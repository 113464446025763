import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SERVER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default async function reportIWBHError(error) {
    try {
        // Add error to Firestore collection 'errors'
        const docRef = await addDoc(collection(db, "errors"), {
            error: error,
            userToken: localStorage.getItem('token'),
            timestamp: new Date(),
        });
        console.log("Error added with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}
