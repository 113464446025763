import React, {lazy} from "react";
import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route,
    Link, BrowserRouter, HashRouter, Routes,
} from "react-router-dom";
import Homepage from "./Components/Pages/Homepage/HomePage";
import Submit from "./Components/Pages/SubmitMessage/Submit";
import EarlyUsers from "./Components/Pages/EarlyUsers/EarlyUsers";
import Profile from "./Components/Pages/Profile/Profile";
import ShowUserPosts from "./Components/Pages/ShowUsersPosts/ShowUserPosts";
import Authentication from "./Components/Pages/Authentication/Authentication";
import SubmitPost from "./Components/Pages/SubmitPost/SubmitPost";
import Settings from "./Components/Pages/Settings/Settings";
import DetailedPostPage from "./Components/Pages/DetailedPost/DetailedPost";
import AdminPage from "./Components/Pages/AdminPage/AdminPage";
import ValidationPostDetailed from "./Components/Pages/ValidationPostDetailed/ValidationPostDetailed";
import ShowUserComments from "./Components/Pages/ShowUserComments/ShowUserComments";
import PostsPage from "./Components/Pages/PostsPage/PostsPage";
import ListUserGroups from "./Components/Pages/ListUserGroups/ListUserGroups";
import GroupPage from "./Components/Pages/GroupPage/GroupPage";
import ListAllGroups from "./Components/Pages/ListAllGroups/ListAllGroups";
import CreateGroup from "./Components/Pages/CreateGroup/CreateGroup";
import CreateGroupPost from "./Components/Pages/GroupPage/CreateGroupPost";
import ChangeUserInterests from "./Components/Pages/ChangeUserInterests/ChangeUserInterests";
import GetUserFavourites from "./Components/Pages/GetUserFavourites/GetUserFavourites";
import TwoFactorEmailValidation from "./Components/Pages/TwoFactorEmailValidation/TwoFactorEmailValidation";
import ComingSoon from "./Components/Pages/ComingSoon/ComingSoon";
import LandingPage from "./Components/Pages/LandingPage/LandingPage";

// Every route - we lazy load so that each page can be chunked
// NOTE that some of these chunks are very small. We should optimize
// which pages are lazy loaded in the future.

export default function Base() {
    return (
        <HashRouter>
            <Routes>
                {/* Homepage */}
                <Route path='/' element={<LandingPage />} />
                <Route path="/submit-suggestion" element={<Submit/>}/>

                <Route path="/user/:id" element={<div></div>}/>
                <Route path="/posts/:id" element={<DetailedPostPage/>}/>
                {/*<Route path="/page/:id" element={<PostsPage />}/>*/}
                <Route path="/feed" element={<Homepage />}/>
                {/*<Route exact path="/" element={<Homepage/>}/>*/}
                <Route path="/daily-challenge" element={<div></div>}/>

                {/* Page for submitting improvements */}
                <Route path="/admin" element={<AdminPage/>}/>

                {/* Page for early users */}
                <Route path="/for-early-users" element={<EarlyUsers/>}/>
                <Route path='/coming-soon' element={<ComingSoon />} />


                <Route path='/validateEmail/:id/:code' element={<TwoFactorEmailValidation />} />
                <Route path='/user/:id/favourites' element={<GetUserFavourites />} />
                <Route path='/user/:id/change-interests' element={<ChangeUserInterests />} />
                <Route path='/groups/:id/create-group-post' element={<CreateGroupPost />} />
                <Route path='/create-group' element={<CreateGroup />} />
                <Route path="/all-groups" element={<ListAllGroups />} />
                <Route path="/groups/:id" element={<GroupPage />} />
                <Route path="/user/:id/groups" element={<ListUserGroups />}/>
                <Route path="/admin" element={<AdminPage/>}/>
                <Route path="/profile/users/:id" element={<Profile/>}/>
                <Route path="/profile/users/:id/posts" element={<ShowUserPosts/>}/>
                <Route path="/profile/users/:id/comments" element={<ShowUserComments />}/>
                <Route path="/admin/validation_queue/:id" element={<ValidationPostDetailed/>}/>
                <Route path="/challenge" element={<div></div>}/>
                <Route path="/register" element={<div></div>}/>
                <Route path="/login" element={<Authentication/>}/>
                <Route path="/submit-post" element={<SubmitPost/>}/>
                <Route path="/profile/users/:id/settings" element={<Settings/>}/>
            </Routes>
        </HashRouter>

    )
}