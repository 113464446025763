import React, {useEffect, useState} from 'react';
import './SubmitPost.css';
import Sidebar from "../../Elements/Sidebar/Sidebar";
import {Multiselect} from "multiselect-react-dropdown";
import makeRequest from "../../Elements/makeRequest";

export default function SubmitPost() {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [selected, setSelected] = useState([]);

    //Selector


    useEffect(() => {
        async function fetchTags() {
            await makeRequest("api/post/tags", 'GET')
                .then(res => res.json())
                .then(data => {
                    if (data.error) {
                        changeErrorText(data.error)
                    } else {
                        // console.log(data);
                        setTags(data.tags);
                    }
                })
        }
        fetchTags()
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault()

        if (title.length > 50) {
            changeErrorText('Title should be less or equal to 50 chars.')
            return;
        }

        if (selected.length > 8) {
            changeErrorText('Maximum allowed amount of tags is 7. Please, remove some of them.')
            return;
        }

        try {
            async function pendValidation() {
                await makeRequest("api/post/pend-validation", 'POST',
                    {
                    "post": {
                        "title": title,
                        "description": description,
                        "tags": selected,
                    },
                    "token": localStorage.getItem('token')
                })
                    .then(data => {
                        if (data.error) {
                            changeErrorText(data.error)
                        } else {
                            changeSuccessText("Thank you! We will review it shortly, and get back to ya!")
                        }
                    })
            }
            pendValidation()

        } catch (e) {
            changeErrorText(e)
        }
    }

    const changeErrorText = (text) => {
        let err = document.getElementById("error_body")
        err.innerText = text
        err.style.backgroundColor = 'rgba(220, 20, 60, 0.9)';
        err.style.display = 'block'
    }

    const changeSuccessText = (text) => {
        let err = document.getElementById("error_body")
        err.style.display = 'block'
        err.innerText = text
        err.style.backgroundColor = "limegreen"
        err.style.color = 'black'
    }

    return (
        <div className="submit__post_page">
            <Sidebar />

            <div style={{margin: "0"}} className="submit__post">
                <div id="error_body" className="error_body">Hello</div>

                <form className="form_submit_post" onSubmit={handleSubmit}>

                    <div style={{margin: "0"}} className="submit_page_input_fields">

                        <div className="submit_post_column_1">
                            <div className="form_header_column">
                                <h1 style={{color: "white", width: '100%'}}>Create a post!</h1>
                                <div className="field">
                                    <div>
                                        <div>
                                            <input type="text"
                                                   style={{padding: '8px'}}
                                                   className="title__input"
                                                   id="title__input"
                                                   placeholder="Your post title"
                                                   value={title}
                                                   onChange={e => setTitle(e.target.value)}
                                                   required={true}
                                            />
                                        </div>

                                        <div>
                                            <div className="multi__select_field field">
                                                <label style={{color: 'black'}} htmlFor="text__input">
                                                    Add tags to Your post:
                                                </label>
                                                
                                                <Multiselect
                                                    className="multi__select"
                                                    isObject={true}
                                                    onKeyPressFn={() => {
                                                    }}
                                                    onRemove={(selectedList, removeItem) => {
                                                        setSelected(selected.filter((val) => val !== removeItem));
                                                    }}
                                                    onSearch={() => {
                                                    }}
                                                    onSelect={(selected, selectedItem) => {
                                                        setSelected([...selected, selectedItem]);
                                                    }}
                                                    options={tags}
                                                    displayValue='tag'
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <textarea
                                rows={4}
                                style={{padding: '8px', color: '#dadada', fontWeight: 500}}
                                id="title__input"
                                className="message__input"
                                placeholder="Today I would like to talk with you about..."
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                required={true}
                            />

                            <button style={{marginTop: "0px"}} onClick={handleSubmit} className="send__button"
                                    type="submit">
                                Send!
                            </button>
                        </div>

                    </div>
                </form>


            </div>


        </div>
    )
}