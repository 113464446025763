import React, {useEffect, useState} from 'react';
import './GetUserFavourites.css';
import makeRequest from "../../Elements/makeRequest";
import {Link, useParams} from "react-router-dom";
import Sidebar from "../../Elements/Sidebar/Sidebar";
import Post from "../../Elements/Post/Post";

export default function GetUserFavourites() {
    const [posts, setPosts] = useState([]);

    let params = useParams();

    useEffect(() => {
        async function fetchLikedPosts() {
            await makeRequest('api/user/' + params.id + '/get-my-favourites?token=' + localStorage.getItem('token'))
                .then(data => {
                    if (data.error) {
                        displayError()
                    } else {
                        setPosts(data.posts)
                    }
                })
        }

        fetchLikedPosts()
    }, []);

    const displayError = () => {}
    const dislpaySuccess = () => {}

    const renderPosts = () => {
        if (!posts || posts.length === 0) {
            return <></>;
        }

        return posts.map((obj, index) => {
            return (
                <Link
                    className='post_post'
                    to={"/posts/" + obj.id} key={index}>
                    <Post key={index}
                          index={index}
                          id={obj.id}
                          title={obj.title}
                          tags={obj.tags}
                          upvotesAmount={obj.upvotes_amount}
                          postAuthor={obj.author}
                          description={obj.description}
                          comments={obj.comments}
                          rep={obj.rep}
                    />
                </Link>
            )
        })
    }

    return (
        <div className='get_user_favourites'>
            <Sidebar />

            <div style={{width: '50%', marginLeft: '200px'}} className='feed'>
                {posts ? renderPosts() : <></>}
            </div>
        </div>
    )
}