import React, {useEffect, useState} from "react";
import "./ActiveThreads.css";
import {GiBoxingGlove} from "react-icons/gi";
import profilePhoto from "../../Images/profile-photo.jpg";
import makeRequest from "../makeRequest";

export default function ActiveThreads({ u }) {

    const [user, setUser] = useState(u);
    const [goal, setGoal] = useState({});

    useEffect(() => {
        async function fetchGoal() {
            await makeRequest('api/goal/', 'GET')
                .then(data => {
                    setGoal(data.goal)
                })
        }
        fetchGoal()
    }, []);

    return (
        <div className="active__threads">
            {user != {} ? <div className="profile">
                <div className="profile__content">
                    <div className="profile_photo">
                        <img src={user.photo_url == 'no_photo' ? "https://cdn.pfps.gg/pfps/1111-husky-2.png" : user.photo_url}
                             alt="profile__photo"
                             className="profile__photo_img"
                        />
                    </div>

                    <div className="profile__names">
                        <h2 className="profile_username">
                            {user.username}
                        </h2>
                        <p className="profile_fullname">
                            {user.fullname}
                        </p>
                    </div>

                    <div className="profile__stats">
                        <p className="upvote_stats">
                            {user.reputation}
                        </p>
                    </div>
                </div>
            </div> : <></>}

            <div className="middle__threads">
                <h2>IWBH News & hot threads</h2>
                <ul className="hot_threads">
                    <li className="thread_element">
                        <a className="left_to_right thread__link" href="/#/for-early-users">
                            Must-read for early users!
                        </a>
                    </li>

                    <li className="thread_element">
                        <a className="left_to_right thread__link" href="/#/submit-suggestion">
                            Submit your idea!
                        </a>
                    </li>

                    <li className="thread_element">
                        <a className="left_to_right thread__link" href="mailto:qniwerniq@gmail.com">
                            Email us!
                        </a>
                    </li>

                </ul>
            </div>

            <div style={{marginTop: "15px"}} className="bottom_threads">
                <a href='/#/submit-suggestion' className='current_goal'>
                    {goal.title}
                    <div className='funded'>
                        {goal.amount}/{goal.desired_amount} $
                    </div>
                </a>
            </div>
        </div>
    )
}