import React, {useEffect, useState} from "react";
// import "./Sidebar.css";
import './SidebarV2.css';
import makeRequest from "../makeRequest";
import { SlFeed } from "react-icons/sl";
import {HiMiniUserGroup} from "react-icons/hi2";
import { MdPodcasts } from "react-icons/md";
import { FaFire } from "react-icons/fa6";
import { GoHeart } from "react-icons/go";
import { VscBell } from "react-icons/vsc";
import { RiProfileLine } from "react-icons/ri";
import { RxExit } from "react-icons/rx";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import reportIWBHError from "../reportError";

export default function Sidebar() {
    const [id, setId] = useState(1)
    const [notifications, setNotifications] = useState([]);
    const [showContainer, setShowContainer] = useState(true);

    async function validateToken() {
        await makeRequest('api/user/data', 'POST', {
            'token': localStorage.getItem('token')
        })
            .then(data => {
                setId(data.user.id)
            })
    }

    async function fetchUserNotifications() {
        await makeRequest("api/user/" + id.toString() + "/notifications", 'GET')
            .then(data => {
                if (data.error) {
                    reportIWBHError(data.error)
                } else {
                    setNotifications(data.notifications)
                }
            })
    }

    const handleShowContainerClick = () => {
        if (showContainer == false) {
            let notifications = document.getElementById('notifications')
            notifications.style.display = 'block'
            setShowContainer(true)
        } else {
            let notifications = document.getElementById('notifications')
            notifications.style.display = 'none'
            setShowContainer(false)
        }
    }

    useEffect(() => {
        validateToken()

        let sidebar = document.querySelector(".sidebar");
        sidebar.classList.toggle("open");
    }, []);

    useEffect(() => {
        fetchUserNotifications()
    }, [id]);

    return (
        <div className="sidebar" style={{boxSizing: 'border-box'}}>
            <div className="logo-details" style={{boxSizing: 'border-box'}}>
                <div className="logo_name">IWBH</div>
                <i className='bx bx-menu' id="btn"></i>
            </div>

            <ul className="nav-list" style={{boxSizing: 'border-box'}}>
                <li>
                    <a href="/#/feed">
                        <i className='bx bx-grid-alt'></i>
                        <span className="links_name">Feed</span>
                    </a>
                    <span className="tooltip">Feed</span>
                </li>

                <li>
                    <a href={"/#/user/" + id.toString() + "/groups"}>
                        <i className='bx bx-group'></i>
                        <span className="links_name">My Groups</span>
                    </a>
                    <span className="tooltip">My Groups</span>
                </li>

                <li>
                    <a href="/#/feed">
                        <i className='bx bx-pie-chart-alt-2'></i>
                        <span className="links_name">Active threads</span>
                    </a>
                    <span className="tooltip">Active threads</span>
                </li>

                <li>
                    <a href={"/#/user/" + id.toString() + "/favourites"}>
                        <i className='bx bx-heart'></i>
                        <span className="links_name">Favourites</span>
                    </a>
                    <span className="tooltip">Favourites</span>
                </li>

                <li>
                    <a href="/#/coming-soon">
                        <i className='bx bx-podcast'></i>
                        <span className="links_name">Our Podcast</span>
                    </a>
                    <span className="tooltip">Coming Soon..</span>
                </li>

                <li>
                    <a href="/#/coming-soon">
                        <i className='bx bx-chat'></i>
                        <span className="links_name">Messages</span>
                    </a>
                    <span className="tooltip">Coming Soon..</span>
                </li>

                <li>
                    <a
                        onClick={e =>
                            handleShowContainerClick()}
                        style={{cursor: 'pointer'}}
                    >
                        <i className='bx bx-bell'></i>
                        <span className="links_name">Notifications &nbsp;
                            {notifications ?
                            <span className={notifications.length > 5 ? "notifications_status_important" : "notifications_status_usual" }>
                                ({notifications ? notifications.length : "0" })
                            </span>
                                :
                                <></> }
                        </span>
                        <div id='notifications' className='notifications'>
                            {notifications && notifications.map((obj, index) => {
                                return (
                                    <div id={obj.id} className='notification'>
                                        {String(obj.created_at).substring(0, 10)} | {obj.description}
                                    </div>
                                )
                            })}
                        </div>
                    </a>
                    <span className="tooltip">Notifications</span>
                </li>

                <li>
                    <a href={"/#/profile/users/" + id.toString()}>
                        <i className='bx bx-user'></i>
                        <span className="links_name">My Profile</span>
                    </a>
                    <span className="tooltip">My Profile</span>
                </li>

                {localStorage.getItem('token') != '' ?
                    <li className="profile">
                        <div className="profile-details">
                            <a href='/#/login'
                               onClick={e => {
                                   localStorage.removeItem('token')
                                   window.location.href = "http://localhost:3000/#/login"
                               }}
                            >
                                <i className='bx bx-export'></i>
                                <div className="name_job">
                                    <div className="name">Logout</div>
                                </div>
                            </a>
                        </div>
                        <i className='bx bx-log-out' id="log_out"></i>
                    </li>
                    : <li className="top__menu_link">
                        <a href="/#/login">
                            Login
                        </a>
                    </li>}

                <li className="profile">
                    <div className="profile-details">
                        <i className='bx bx-export'></i>
                        <div className="name_job">
                            <a className="name" onClick={e => {
                                localStorage.removeItem('token')
                                window.location.href = "http://localhost:3000/#/login"
                            }}>Logout</a>
                        </div>
                    </div>
                    <i className='bx bx-log-out' id="log_out"></i>
                </li>
            </ul>
        </div>
    )
}