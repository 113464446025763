import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SERVER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();

export default async function storeMessage({ email, message }) {

    try {
        const docRef = await addDoc(collection(db, "messages"), {
            "email": email,
            "message": message,
            "timestamp": Date.now(),
        });
        alert("We will consider your suggestion. Thank you for helping us to improve!")
    } catch (e) {
        alert("Error adding document: "+ e);
    }
}