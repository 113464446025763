import React, { useState, useEffect, useRef } from 'react';
import './TwoFactorEmailValidation.css';
import {useParams} from "react-router-dom";
import makeRequest from "../../Elements/makeRequest";

export default function TwoFactorEmailValidation() {
    const [verified, setVerified] = useState(false);
    let params = useParams();

    useEffect(() => {
        async function validateUser() {
            await makeRequest('api/user/' + params.id.toString() + '/validateUserEmail',  'POST', {
                "two_fa_code": params.code,
            })
                .then(data => {
                    if (!data.error) {
                        setVerified(true)
                        displaySuccess("Your email was successfully verified. You are redirected to the homepage.")
                        setTimeout(() => {
                            window.location.href = "/#/feed";
                        }, 5000);
                    } else {
                        displayError(data.error)
                    }
                })
        }
        validateUser()
    }, []);

    const displayError = (message) => {
        let error = document.getElementById('error')
        error.style.display = 'block'
        error.innerText = message
    }

    const displaySuccess = (message) => {
        let success = document.getElementById('success')
        success.style.display = 'block'
        success.innerText = message
    }

    return (
        <div>
            <div className='error' id='error'></div>
            <div className='success' id='success'></div>
            <h1 style={{color: 'white'}} >
                Thank you for submission, your email is verified now.
            </h1>
        </div>
    )
}