import React, { useState } from "react";
import "./Authentication.css";
import money from '../../Images/money.png';
import arts from '../../Images/arts.png';
import bitcoin from '../../Images/bitcoin.png';
import business from '../../Images/business.png';
import coding from '../../Images/coding.png';
import education from '../../Images/education.png';
import enstein from '../../Images/enstein.png';
import health from '../../Images/health.png';
import history from '../../Images/history.png';
import mentalHealth from '../../Images/mentalHealth.png';
import mindset from '../../Images/mindset.png';
import technology from '../../Images/technology.png';
import marcusAurelius from "../../Images/marcus-aurelius.png";
import sheckspear from "../../Images/shekspear.png";
import career from "../../Images/career.png";
import diet from "../../Images/diet.png";

import makeRequest from "../../Elements/makeRequest";


export default function Authentication() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');
    const [isRegister, setIsRegister] = useState(localStorage.getItem('token') == '');
    const [interests, setInterests] = useState([]);
    const [proceedInterests, setProceedInterests] = useState(false);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const changeErrorText = (text) => {
        let err = document.getElementById("error_body")
        err.innerText = text
        err.style.backgroundColor = 'rgba(220, 20, 60, 0.9)';
        err.style.display = 'block'
    }

    const changeSuccessText = (text) => {
        let err = document.getElementById("error_body")
        err.style.display = 'block'
        err.innerText = text
        err.style.backgroundColor = "limegreen"
        err.style.color = 'black'
    }

    const handleRegister = (e) => {
        e.preventDefault();

        if (password != confirmPassword) {
            changeErrorText("Confirm password not equal to password")
            return;
        }

        if (!validateEmail(email)) {
            changeErrorText("Wrong email format.")
            return;
        }

        async function register() {
            await makeRequest('api/register', 'POST', {
                "user": {
                    "email": email,
                    "password": password,
                    "username": username,
                },
                "interests": interests,
            })
                .then(data => {
                    if (!data.error) {
                        changeSuccessText("Congratulations! We are happy to welcome you in our community!")
                        localStorage.setItem('token', data.token.token)
                        if (data.token !== '') {
                            setTimeout(() => {
                                window.location.href = "/#/feed";
                            }, 5000);
                        } else {
                            window.location.href = "/#/feed";
                        }
                    } else {
                        changeErrorText(data.error)
                    }
                })
            }
        register()
    }

    const handleLogin = (e) => {
        e.preventDefault();

        const body = {
            "email": email,
            "password": password,
        }

        async function authenticate() {
            await makeRequest("api/authenticate", 'POST', body)
                .then(data => {
                    if (data.error) {
                        changeErrorText(data.error)
                    } else {
                        changeSuccessText("Welcome back!")
                        localStorage.setItem("token", data.token)
                        window.location.href = "/#/feed"
                    }
                })
        }
        authenticate()
    }

    const handleCheckboxChange = (interestId, isChecked) => {
        if (isChecked) {
            setInterests(prevInterests => [...prevInterests, interestId]);
        } else {
            setInterests(prevInterests => prevInterests.filter(id => id !== interestId));
        }
    };

    const handleSetInterests = () => {
        setIsRegister(true)
    }

    const renderPage = () => {
        const initInterests = [
            {
                id: 'money',
                name: 'money',
                icon: <img style={{width: '80px', height: '80px'}} src={money} alt='money' />
            },
            {
                id: 'literature',
                name: 'Literature',
                icon: <img style={{width: '80px', height: '80px'}} src={sheckspear} alt='mental_health'/>
            },
            {
                id: 'mindset',
                name: 'Mindset',
                icon: <img style={{width: '80px', height: '80px'}} src={mindset} alt='mindset'/>
            },
            {
                id: 'business',
                name: 'Business',
                icon: <img style={{width: '80px', height: '80px'}} src={business} alt='business'/>
            },
            {
                id: 'crypto',
                name: 'crypto',
                icon: <img style={{width: '80px', height: '80px'}} src={bitcoin} alt='crypto'/>
            },
            {
                id: 'history',
                name: 'History',
                icon: <img style={{width: '80px', height: '80px'}} src={history} alt='history'/>
            },
            {
                id: 'code',
                name: 'Coding',
                icon: <img style={{width: '80px', height: '80px'}} src={coding} alt='coding'/>
            },
            {
                id: 'tech',
                name: 'Technologies',
                icon: <img style={{width: '80px', height: '80px'}} src={technology} alt='tech'/>
            },
            {
                id: 'mentalHealth',
                name: 'Mental Health',
                icon: <img style={{width: '80px', height: '80px'}} src={mentalHealth} alt='mental_health'/>
            },
            {
                id: 'diets',
                name: 'Diets',
                icon: <img style={{width: '80px', height: '80px'}} src={diet} alt='enstein' />
            },
            {
                id: 'career',
                name: 'Career',
                icon: <img style={{width: '80px', height: '80px'}} src={career} alt='health'/>
            },
            {
                id: 'stoicism',
                name: 'Stoicism',
                icon: <img style={{width: '80px', height: '80px'}} src={marcusAurelius} alt='tech'/>
            }
        ];

        if (!isRegister) {
            return (
                <>
                    <form className="register__form" onSubmit={handleLogin}>
                        <label htmlFor="email__input">
                            Enter your email:
                        </label>
                        <input type="text"
                               className="email__input"
                               id="email__input"
                               placeholder="qniwerniq@gmail.com"
                               value={email}
                               onChange={e => setEmail(e.target.value)}
                               required={true}
                        />

                        <label htmlFor="password">
                            Password:
                        </label>
                        <input
                            className="email__input"
                            id="password"
                            placeholder='**********'
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required={true}
                        />
                        <button style={{marginLeft: '28px', color: 'black'}} className="submit__button"
                                type="submit">
                            {isRegister ? "Register" : "Login"}
                        </button>
                    </form>

                    <div className='bottom-text'>
                        <p>Don't have an account?
                            <button onClick={e => setIsRegister(true)}>
                                Sign Up
                            </button>
                        </p>

                        {/*<p>*/}
                        {/*    <button href="#">Forgot password?</button>*/}
                        {/*</p>*/}
                </div>
        </>)
        }

        if (isRegister && !proceedInterests) {
            return (
                <div className="choose_interests">
                    <h1>Choose your interests!</h1>

                    <form onSubmit={e => setProceedInterests(true)}>
                        {initInterests.map(interest => (
                            <div key={interest.id} className="box">
                                <input type="checkbox" id={interest.id}
                                       onChange={e => handleCheckboxChange(interest.id, e.target.checked)}
                                />
                                <label style={{padding: "70px 40px 50px 40px"}} htmlFor={interest.id}>
                                    {interest.icon}
                                    <span style={{marginTop: '0'}}>{interest.name}</span>
                                </label>
                            </div>
                        ))}
                        <button type='submit' onClick={e => handleSetInterests()}
                            className='submit__button'
                                style={{color: 'black'}}
                        >
                            Let's go!
                        </button>
                    </form>

                    <div className='bottom-text'>
                        <p>Have an account?</p>
                        <button onClick={e => setProceedInterests(true)}>
                            Log in
                        </button>
                    </div>
                </div>
            )
        }

        if (isRegister && proceedInterests) {
            return <form className="register__form" onSubmit={handleRegister}>
                <label htmlFor="email__input">
                    Your Email
                </label>
                <input type="text"
                       className="email__input"
                       id="email__input"
                       placeholder="qniwerniq@gmail.com"
                       value={email}
                       onChange={e => setEmail(e.target.value)}
                       required={true}
                />

                <label htmlFor="password">
                    Password
                </label>
                <input
                    className="email__input"
                    id="password"
                    placeholder='**********'
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required={true}
                />

                <label htmlFor="password">
                    Confirm password
                </label>
                <input
                    className="email__input"
                    id="password"
                    placeholder='**********'
                    type="password"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    required={true}
                />

                <label htmlFor="password">
                    Username
                </label>
                <input
                    className="email__input"
                    id="password"
                    placeholder='qniwwwersss'
                    type="text"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    required={true}
                />

                <button style={{marginLeft: '28px', marginTop: '10px', color: 'black'}}
                        className="authentication_submit__button" type="submit">
                    {isRegister ? "Register" : "Login"}
                </button>

                <div className='bottom-text'>
                    <p>Have an account?
                        <button style={{cursor: 'pointer'}} onClick={e => setIsRegister(!isRegister)}>
                            Log in
                        </button>
                    </p>
                    {/*<p>*/}
                    {/*    <button href="#">Forgot password?</button>*/}
                    {/*</p>*/}
                </div>
            </form>
        }
    }

    return (
        <div className="authentication">
            <div className="authentication_body">
                <div id="error_body" className="error_body"></div>

                <div className="authentication_card">
                    <div className="body">
                        <div className="authentication__page_content" style={{
                            marginTop: "25px",
                        }}>
                            <div className="authentication_titles">
                                <h1 className="card_title">{isRegister ? "Register to join!" : "Welcome back!"}</h1>
                                <h6 className="register__subtitle">Join best community of self-cultivated people</h6>
                            </div>

                            {/*{isLogin ? "Log in to start!" : "Register to join!"}*/}
                            {renderPage()}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}