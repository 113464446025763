import React, {useEffect, useState} from 'react';
import './Settings.css';
import Sidebar from "../../Elements/Sidebar/Sidebar";
import {Link, useParams} from "react-router-dom";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {initializeApp} from "firebase/app";
import makeRequest from "../../Elements/makeRequest";

export default function Settings() {

    const params = useParams()


    return (
        <div className="settings_page">
            <Sidebar/>

            <div className="settings_feed">
                <div className="nav_section">
                    <ul className="navigation">
                        <li className="nav_element">
                            <Link to={"/profile/users/" + params.id}>
                                User's Profile
                            </Link>
                        </li>

                        <li className="nav_element">
                            <Link to={"/profile/users/" + params.id + "/posts"}>
                                Posts
                            </Link>
                        </li>

                        <li className="nav_element">
                            <Link to={"/profile/users/" + params.id + "/comments"}>
                                Comments
                            </Link>
                        </li>

                        <li className="nav_element">
                            <Link to={"/profile/users/" + params.id + "/settings"}>
                                Settings
                            </Link>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    )
}