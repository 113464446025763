import React, { useState, useEffect, useRef } from 'react';
import './Search.css';
import makeRequest from "../makeRequest";
import { Link } from "react-router-dom";

export default function Search({ searchOnly }) {
    const [searchVal, setSearchVal] = useState('');
    const [results, setResults] = useState({});
    const [isTyping, setIsTyping] = useState(false);
    const inputRef = useRef(null);
    let MaxListingAmount = 4;


    useEffect(() => {
        function handleClickOutside() {
            setIsTyping(false);
        }

        document.body.addEventListener('click', handleClickOutside);
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isTyping) {
            inputRef.current.focus();
        }
    }, [isTyping]);

    async function fetchResults() {
        await makeRequest('api/search?q=' + searchVal, 'GET')
            .then(data => {
                setResults(data);
            });
    }

    const handleInputChange = (e) => {
        setSearchVal(e.target.value);
        setIsTyping(true);

        fetchResults();
    };

    if (searchOnly) {
       switch (searchOnly) {
           case 'posts':
               return (
                   <div className='search'>
                       <div className="search-box">
                           <button className="btn-search">
                               <i style={{ marginTop: '18px' }} className="fas fa-search"></i>
                           </button>
                           <input
                               ref={inputRef}
                               value={searchVal}
                               onChange={(e) => handleInputChange(e)}
                               type="text"
                               className="input-search"
                               placeholder="Search..."
                           />
                       </div>

                       <div className={!isTyping ? "d-none" : 'search-results'}>
                           <div className='search-posts'>
                               <h3>Posts</h3>
                               {results.posts && results.posts.slice(0, MaxListingAmount).map((obj, index) => (
                                   <Link to={'/posts/' + obj.id.toString()} key={index}>
                                       <div className='user-item'>
                                           <p>{obj.title}</p>
                                       </div>
                                   </Link>
                               ))}
                           </div>
                       </div>
                   </div>
               );

           case 'groups':
               return (
                   <div className='search'>
                       <div className="search-box">
                           <button className="btn-search">
                               <i style={{ marginTop: '18px' }} className="fas fa-search"></i>
                           </button>
                           <input
                               ref={inputRef}
                               value={searchVal}
                               onChange={(e) => handleInputChange(e)}
                               type="text"
                               className="input-search"
                               placeholder="Search..."
                           />
                       </div>

                       <div className={!isTyping ? "d-none" : 'search-results'}>
                           <div className='search-posts'>
                               <h3>Groups</h3>
                               {results.groups && results.groups.slice(0, MaxListingAmount).map((obj, index) => (
                                   <Link to={'/groups/' + obj.id.toString()} key={index}>
                                       <div className='user-item'>
                                           <p>{obj.title}</p>
                                       </div>
                                   </Link>
                               ))}
                           </div>
                       </div>
                   </div>
               );

           case 'users':
               return (
                   <div className='search'>
                       <div className="search-box">
                           <button className="btn-search">
                               <i style={{ marginTop: '18px' }} className="fas fa-search"></i>
                           </button>
                           <input
                               ref={inputRef}
                               value={searchVal}
                               onChange={(e) => handleInputChange(e)}
                               type="text"
                               className="input-search"
                               placeholder="Search..."
                           />
                       </div>

                       <div className={!isTyping ? "d-none" : 'search-results'}>
                           <div className='search-users'>
                               <h3>Users</h3>
                               {results.users && results.users.slice(0, MaxListingAmount).map((obj, index) => (
                                   <Link to={'/profile/users/' + obj.id.toString()} key={index}>
                                       <div className='post-item'>
                                           <p>{obj.firstname}</p>
                                       </div>
                                   </Link>
                               ))}
                           </div>
                       </div>
                   </div>
               );
       }
    } else {
        return (
            <div className='search'>
                <div className="search-box">
                    <button className="btn-search">
                        <i style={{marginTop: '18px'}} className="fas fa-search"></i>
                    </button>
                    <input
                        ref={inputRef}
                        value={searchVal}
                        onChange={(e) => handleInputChange(e)}
                        type="text"
                        className="input-search"
                        placeholder="Search..."
                    />
                </div>

                <div className={!isTyping ? "d-none" : 'search-results'}>
                    <div className='search-users'>
                        <h3>Users</h3>
                        {results.users && results.users.slice(0, MaxListingAmount).map((obj, index) => (
                            <Link to={'/profile/users/' + obj.id.toString()} key={index}>
                                <div className='post-item'>
                                    <p>{obj.firstname}</p>
                                </div>
                            </Link>
                        ))}
                    </div>

                    <div className='search-posts'>
                        <h3>Posts</h3>
                        {results.posts && results.posts.slice(0, MaxListingAmount).map((obj, index) => (
                            <Link to={'/posts/' + obj.id.toString()} key={index}>
                                <div className='user-item'>
                                    <p>{obj.title}</p>
                                </div>
                            </Link>
                        ))}
                    </div>

                    <div className='search-groups'>
                        <h3>Groups</h3>
                        {results.groups && results.groups.slice(0, MaxListingAmount).map((obj, index) => (
                            <Link to={'/groups/' + obj.id.toString()} key={index}>
                                <div className='post-item'>
                                    <p>{obj.title}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
