import React, {useEffect, useState} from 'react';
import './AdminPage.css';
import {TbPyramid} from "react-icons/tb";
import {BsMicrosoft} from "react-icons/bs";
import {HiViewGrid} from "react-icons/hi";
import {CiGrid42} from "react-icons/ci";
import {FaBook, FaHistory, FaRegCompass, FaWindows} from "react-icons/fa";
import {MdOutlineAnalytics} from "react-icons/md";
import {SiGoogleanalytics} from "react-icons/si";
import {GiPieChart} from "react-icons/gi";
import {IoIosSettings} from "react-icons/io";
import makeRequest from "../../Elements/makeRequest";
import StatisticsCard from "../../Elements/StatisticsCards/StatisticsCards";
import {Link} from "react-router-dom";

export default function AdminPage() {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [newUsers, setNewUsers] = useState(0);

    const [newPosts, setNewPosts] = useState(0);
    const [postsForValidation, setPostsForValidation] = useState([]);
    const [validationQueueLength, setValidationQueueLength] = useState(0);

    const toggleSidebar = () => {
        setSidebarCollapsed(!sidebarCollapsed);
    };

    const arrows = document.querySelectorAll(".arrow");

    useEffect(() => {
        async function getNewUsersCount() {
            await makeRequest('admin/new-users-count', 'POST', {
                "token": localStorage.getItem('token')
            }).then(data => {
                setNewUsers(data.length)
                setIsAdmin(true);
            })
        }

        getNewUsersCount()

        async function getNewPostsCount() {
            await makeRequest('admin/all-posts', 'POST', {
                "token": localStorage.getItem('token')
            }).then(data => {
                console.log(data)
                setNewPosts(data.postsLength)
            })
        }

        getNewPostsCount()

        async function getPostsForValidation() {
            await makeRequest('admin/all-validation-queue', 'POST', {
                'token': localStorage.getItem('token')
            }).then(data => {
                setPostsForValidation(data.posts)
                setValidationQueueLength(data.length)
            })
        }

        getPostsForValidation()
    }, []);

    return (
        <div className={`admin__page ${sidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
            <div className="admin_sidebar">
                <div className="logo-details">
                    <span style={{padding: '5px 15px'}} className="logo_name">IWBH Admin</span>
                </div>

                <ul className="nav-links">
                    <li>
                        <div className="icon-link">
                            <a href="/#/admin">
                                <FaWindows/>
                                <span className="link_name">Dashboard</span>
                            </a>
                            <i className='bx bx-grid-alt'></i>
                        </div>
                            <ul className="sub-menu blank">
                                <li><a className="link_name" href="#">Category</a></li>
                            </ul>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <CiGrid42/>
                                <span className="link_name">Category</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>
                        <ul className="sub-menu">
                        <li><a className="link_name" href="#">Category</a></li>
                            <li><a href="#">HTML & CSS</a></li>
                            <li><a href="#">JavaScript</a></li>
                            <li><a href="#">PHP & MySQL</a></li>
                        </ul>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <FaBook/>
                                <span className="link_name">Posts</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>

                        <ul className="sub-menu">
                            <li><a className="link_name" href="#">Posts</a></li>
                            <li><a href="#">Web Design</a></li>
                            <li><a href="#">Login Form</a></li>
                            <li><a href="#">Card Design</a></li>
                        </ul>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <SiGoogleanalytics/>
                                <span className="link_name">Analytics</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>

                        <ul className="sub-menu">
                        <li><a className="link_name" href="#">Analytics</a></li>
                        </ul>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <GiPieChart/>
                                <span className="link_name">Chart</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>

                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="#">Chart</a></li>
                        </ul>
                    </li>

                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <FaRegCompass/>
                                <span className="link_name">Explore</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>

                        <ul className="sub-menu blank">
                        <li><a className="link_name" href="#">Explore</a></li>
                        </ul>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <FaHistory/>
                                <span className="link_name">History</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>

                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="#">History</a></li>
                        </ul>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <IoIosSettings/>
                                <span className="link_name">Setting</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>
                        <ul className="sub-menu blank">
                        <li><a className="link_name" href="#">Setting</a></li>
                            </ul>
                    </li>
                    <li>
                        <div className="profile-details">
                            <div className="profile-content">
                                <img src="http://placebeard.it/250/250" alt="profileImg"/>
                            </div>
                            <div className="name-job">
                                <div className="profile_name">@Qniwwwersss</div>
                                <div className="job">Software Engineer</div>
                            </div>
                            <i className='bx bx-log-out'></i>
                        </div>
                    </li>
                </ul>
            </div>

            <div className="admin__content">
                <div className="statistics_cards">
                    <div className="statistics_cards">
                        <StatisticsCard data={newUsers} title={"Total Users"}/>
                    </div>

                    <div className="statistics_cards pink">
                        <StatisticsCard data={newPosts} title={"Total posts "}/>
                    </div>

                    <div className="statistics_cards">
                        <StatisticsCard data={validationQueueLength} title={"New Posts"}/>
                    </div>
                </div>


                <div className="validation_queue">
                    <div className="queue_header">
                        <p>Posts waiting approval</p>
                    </div>

                    <div className="queue_headers">
                        {postsForValidation && postsForValidation.map((obj, index) => {
                            return (
                                <>
                                    <Link to={'/admin/validation_queue/' + obj.id.toString()}>
                                        <div className="queue_post">
                                            <h3 className="queue_post_title">
                                                {obj.title}
                                            </h3>
                                            <p className="queue_post_description">
                                                {obj.description}
                                            </p>

                                            <div className="queue_post_status">
                                                Pending
                                            </div>

                                            <p className="queue_post_timestamp">
                                                {obj.created_at.substring(0, 10)}
                                            </p>

                                            <div className="validate_reject">
                                                <button className="queue_post_validate">
                                                    Validate
                                                </button>
                                                <button className="queue_post_reject">
                                                    Reject
                                                </button>

                                            </div>
                                        </div>
                                    </Link>
                                </>
                            )
                        })}

                    </div>

                </div>
            </div>


        </div>
    )
}
