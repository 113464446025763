import React, {useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import PaymentForm from "./DonateForm";


const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

export default function StripeContainer() {

    // const [amount, setAmount] = useState(1000);
    const [clientSecret, setClientSecret] = useState('1');

    return (
        <>
        {clientSecret ?
            <div style={{width: '100%'}} className="stripe__container">
            <h1 style={{width: '100%'}}>Leave a tip for us, if you want more updates and content</h1>
                        <Elements stripe={stripePromise} options={{
                            mode: 'payment',
                            currency: 'usd',
                            amount: 1000,
                    }}>
                        <PaymentForm />
                    </Elements>
            </div>
                : <></>}
        </>
    )
}