import React, { useState, useEffect } from "react";
import makeRequest from "../../Elements/makeRequest";
import Sidebar from "../../Elements/Sidebar/Sidebar";
import ActiveThreads from "../../Elements/ActiveThreads/ActiveThreads";
import StripeContainer from "../../Elements/DonateForm/StripeContainer";
import './Submit.css';
import reportIWBHError from "../../Elements/reportError";

const Submit = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [user, setUser] = useState({});
    const [active, setActive] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        async function fetchData() {
            await makeRequest('api/user/data', 'POST', {"token": localStorage.getItem('token')})
                .then(data => {
                    if (data.error) {
                        reportIWBHError(data.error)
                    } else {
                        setUser(data.user)
                        setActive(true)
                    }
                })
        }

        fetchData();
    }, [])

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            alert('Please, enter valid email.')
        } else {
            // Store message
            await makeRequest('api/storeMessage', 'POST', { email, message })
                .then(response => {
                    if (response.error) {
                        setErrorMessage(response.error);
                    } else {
                        // Clear form inputs
                        setEmail('');
                        setMessage('');

                        alert(response.message)
                    }
                })
                .catch(error => {
                    console.error('Error storing message:', error);
                    setErrorMessage('Error storing message. Please try again later.');
                });
        }
    };

    return (
        <div className="submit__page">
            <Sidebar />

            <div className="submit__page_content">
                <div className="titles">
                    <h1>Submit suggestion</h1>
                    <h6 className="submit__subtitle">We value your opinion!</h6>
                </div>

                <form className="message_form" onSubmit={handleSubmit}>
                    <label htmlFor="email__input">
                        Enter your email:
                    </label>
                    <input
                        type="text"
                        className="email__input"
                        id="email__input"
                        placeholder="qniwerniq@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required={true}
                    />

                    <label htmlFor="text__input">
                        Your message:
                    </label>
                    <textarea
                        className="text__input"
                        id="text__input"
                        placeholder="It would be awesome, if you..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required={true}
                    />

                    <button className="submit__button" type="submit">
                        Send!
                    </button>
                </form>

                <div className='donation_form'>
                    <StripeContainer />
                </div>

                {errorMessage && <div>{errorMessage}</div>}
            </div>

            {active ? <ActiveThreads u={user}/> : <></>}
        </div>
    );
};

export default Submit;
