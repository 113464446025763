import React, { useEffect } from 'react';
import './LandingPage.css';

export default function LandingPage() {
    if (localStorage.getItem('token') == undefined) {
        console.log('User is not authenticated')
    } else {
        window.location.href = 'https://www.iwbh.me/#/feed'
    }


    useEffect(() => {
        // After 8 seconds, add 'show' class to the link to trigger the animation
        const timer = setTimeout(() => {
            const signUpButton = document.querySelector('.landing_page_container');
            if (signUpButton) {
                signUpButton.style.display = 'block';
            }
        }, 7500);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className='landing_page'>
            <video width="100%" height="100%" autoPlay muted>
                <source  src="/Videos/bgVideo.mp4" type="video/mp4"/>
            </video>
            <div className='landing_page_container'>
                <p>
                    IWBH is a platform for people who want to grow and be the best version of themselves. <br/>
                    We will help you to be better everyday!
                </p>
                <a className='sign_up_button' href='/#/login'>
                    Sign up now!
                </a>
            </div>
        </div>
    );
}
