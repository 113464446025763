import React, {useEffect, useState} from 'react';
import './ShowUserComments.css';
import Sidebar from "../../Elements/Sidebar/Sidebar";
import {Link, useParams} from "react-router-dom";
import makeRequest from "../../Elements/makeRequest";
import SmallPostCard from "../../Elements/Post/SmallPostCard";
import CommentsSection from "../../Elements/Comments/CommentsSection";

export default function ShowUserComments() {
    let params = useParams();
    const [comments, setComments] = useState([]);


    useEffect(() => {
        async function fetchComments() {
            await makeRequest('api/user/' + params.id.toString() + '/comments', 'GET')
                .then(data => {
                    setComments(data.comments)
                })
        }

        fetchComments()
    }, []);

    return (
        <div className="settings_page">
            <Sidebar/>

            <div className="profile_feed">
                <div className="nav_section">
                    <ul className="navigation">
                        <li className="nav_element">
                            <Link to={"/profile/users/" + params.id}>
                                User's Profile
                            </Link>
                        </li>

                        <li className="nav_element">
                            <Link to={"/profile/users/" + params.id + "/posts"}>
                                Posts
                            </Link>
                        </li>

                        <li className="nav_element">
                            <Link to={"/profile/users/" + params.id + "/comments"}>
                                Comments
                            </Link>
                        </li>

                        <li className="nav_element">
                            <Link to={"/profile/users/" + params.id + "/settings"}>
                                Settings
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}