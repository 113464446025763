import React, {useEffect, useState} from 'react';
import './CommentsSection.css';
import './CommentsUtils'
import makeRequest from "../makeRequest";
import pen from '../../Images/Pen.png';
import tick from "../../Images/post-buttons/tick.png";
import cross from "../../Images/white-cross.png";

export default function CommentsSection({ postId, initialComments }) {
    const [comments, setComments] = useState(initialComments);
    const [user, setUser] = useState({});
    const [value, setValue] = useState('');
    const [showForm, setShowForm] = useState(false);

    const [showReplyForm, setShowReplyForm] = useState(true);
    const [replyForm, setReplyForm] = useState(0);


    async function fetchComments() {
        await makeRequest('api/post/' + postId.toString() + '/comments', 'GET')
            .then(data => {
                setComments(data.comments)
            })
    }

    useEffect(() => {
        fetchData();
        fetchComments()
    }, []);

    async function fetchData() {
        await makeRequest('api/user/data', 'POST', {
            "token": localStorage.getItem('token')
        })
            .then(data => {
                if (data.error) {
                    if (data.error == 'sql: no rows in result set') {
                        window.location.href = '/#/login';
                    }
                    // TODO: create better error handling
                } else {
                    setUser(data.user)
                }
            })
    }

    async function createComment(postId, parentId) {
        fetchData()

        await makeRequest('api/post/' + postId.toString() + '/create-comment', 'POST', {
            'parent_comment_id': parseInt(parentId),
            'post_id': parseInt(postId),
            'author_id': parseInt(user.id),
            'content': value,
        })
    }


    const toggleReplyForm = (b, id) => {
        setReplyForm(id)
        setShowReplyForm(!showReplyForm)
    };

    const handleReply = (parentId) => {
        setShowReplyForm(false);
        setValue('');

        createComment(postId, parentId);
        fetchComments();
    };

    const renderReplies = (replies, depth = 0) => {
        return replies && replies.map(reply => (
            <details key={reply.id} open className="comment" style={{marginLeft: `${30}px`}}>
                <summary>
                    <div className="comment-heading">
                        <div className="comment-info">
                            <span className="comment-author">{reply.author.username}, &nbsp;</span>
                            <span
                                className="comment_subtitle">{reply.author.firstname} {reply.author.lastname} | </span>
                                <p className="m-0">{reply.votes ? reply.votes : 1} points &bull; {String(reply.created_at).substring(0, 10)}</p>
                        </div>
                    </div>
                </summary>
                <div className="comment-body">
                <p>{reply.content}</p>
                    <div className='reply_button'>
                        <button type="button" onClick={() => toggleReplyForm(false, reply.id)}>Reply</button>
                    </div>
                    <form
                        method="POST"
                        className={showReplyForm && replyForm === reply.id ? "reply-form" : "reply-form d-none"}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleReply(reply.id);
                        }}
                    >
                        <div className="text-input">

                            <textarea placeholder="Your thoughts?" onChange={e => setValue(e.target.value)} rows={1} name="content" id="content"/>
                        </div>
                        <div className='comment_buttons'>
                            <button className='comment_button' type="submit">Submit</button>
                            <button className='comment_button' type="button"
                                    onClick={() => toggleReplyForm(false)}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
                {reply.replies && reply.replies.length > 0 && renderReplies(reply.replies, depth + 1)}
            </details>
        ));
    };

    return (
        <>
            <div className='leave_a_comment'>
                <button
                    style={{marginLeft: '100px', border: '1px solid #6E7090'}}
                    onClick={e => setShowForm(true)} className='create-post'>
                    <img src={pen} alt='create_post_icon'/>
                    <p>
                        Write down a comment
                    </p>
                </button>
            </div>

            {showForm && (
                <div className="modal">
                    <div className="modal-content">
                        <form
                            style={{height: '350px'}}
                            onSubmit={e => {
                            e.preventDefault()
                            handleReply(0)
                        }}>
                            <h2>Write a reply</h2>

                            <div className='post_input'>
                                <label htmlFor='content'>
                                    Content
                                </label>
                                <textarea value={value}
                                          placeholder='Your Comment'
                                          id='content' rows={6}
                                          onChange={e => setValue(e.target.value)}/>
                            </div>

                            <div className='action_buttons'>
                                <button className='submit_post_button'
                                    style={{width: '200px'}}
                                    type='submit'
                                >
                                    <img src={tick} alt='cross'/>
                                    Leave a comment
                                </button>
                                <button onClick={e => setShowForm(false)}
                                        className='exit_post_create'>
                                    <img src={cross} width={24} height={24} alt='cross'/>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <div className="comment-thread">
                {comments && comments.map(comment => {
                    if (comment.parent_comment_id) {
                        return null;
                    }

                    return (
                        <details key={comment.id} open className="comment">
                            <summary>
                                <div className="comment-heading">
                                    <div className="comment-info">
                                        <span className="comment-author">{comment.author.username}, &nbsp;</span>
                                        <span
                                            className="comment_subtitle">{comment.author.firstname} {comment.author.lastname} | </span>
                                        <p className="m-0">{comment.votes ? comment.votes : 1} points &bull; {String(comment.created_at).substring(0, 10)}</p>
                                    </div>
                                </div>
                            </summary>

                            <div className="comment-body">
                                <p>{comment.content}</p>
                                <div className='reply_button'>
                                    <button type="button" onClick={() => toggleReplyForm(false, comment.id)}>
                                        Reply
                                    </button>
                                </div>

                                <form
                                    method="POST"
                                    className={showReplyForm && replyForm === comment.id ? "reply-form" : "reply-form d-none"}
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleReply(comment.id);
                                    }}
                                >
                                    <div className="text-input">
                                        <textarea
                                            aria-placeholder="Your thoughts?"
                                            onChange={e => setValue(e.target.value)} rows={1} name="content" id="content" placeholder=" "/>
                                    </div>

                                    <div className='comment_buttons'>
                                        <button className='comment_button' type="submit">Submit</button>
                                        <button className='comment_button' type="button"
                                                onClick={() => toggleReplyForm(false)}>
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div className="replies">
                                {comment.replies && comment.replies.length > 0 && renderReplies(comment.replies, 0)}
                            </div>
                        </details>
                    )
                })}
            </div>
        </>
    )
}