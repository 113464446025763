import React, {useEffect, useState} from 'react';
import './ListGroups.css';
import Sidebar from "../../Elements/Sidebar/Sidebar";
import makeRequest from "../../Elements/makeRequest";
import {Link, useParams} from "react-router-dom";
import Search from "../../Elements/SearchBar/Search";

export default function ListUserGroups() {
    let params = useParams();

    const [isAllGroups, setAllGroups] = useState(false);
    const [groups, setGroups] = useState([]);

    async function fetchGroups() {
        await makeRequest('api/user/' + params.id.toString() + '/groups', 'GET')
            .then(data => {
                setGroups(data.groups);
            })
    }

    useEffect(() => {
        fetchGroups()
    }, []);

    const displayError = (message) => {
        let error = document.getElementById('error')
        error.innerText = message;
        error.style.display = 'block';
    }

    const displaySuccess = (message) => {
        let success = document.getElementById('success')
        success.innerText = message;
        success.style.display = 'block';
    }

    const handleUnfollow = (e, groupId) => {
        e.preventDefault()

        async function handleUserUnfollow() {
            await makeRequest('api/groups/' + groupId.toString() + '/unfollow', 'POST', {
                'token': localStorage.getItem('token'),
            })
                .then(data => {
                    if (data.error) {
                        displayError(data.error)
                    } else {
                        displaySuccess(data.message)
                    }
                })
        }
        handleUserUnfollow()
        fetchGroups()
    }

    return (
        <div className='list_groups_page'>
            <Sidebar />

            <div className='list_groups_feed'>
                <div className='search_and_create'>
                    <Search searchOnly={'groups'}/>

                    <a className='create-group' href="/#/create-group">
                        Create my group
                    </a>
                </div>

                <div style={{marginTop: '15px'}} className='check_all_group'>
                    <a href='/#/all-groups' style={{color: 'white', fontSize: '20px'}}>
                        Check All Groups
                    </a>
                </div>

                <div id='error' className='error'></div>
                <div id='success' className='success'></div>
                <div className='user_groups'>
                    <div className='groups'>
                        {groups && groups.map((obj, ind) => {
                            return (
                                <>
                                    <Link to={'/groups/' + obj.id.toString()}>
                                        <div className='group'>
                                            <img src={obj.photo_url} alt={obj.title} className='group_icon'/>

                                            <div className='group_description_container'>
                                                <p className='group_title'>
                                                    {obj.title} | Followers: {obj.followers}
                                                </p>

                                                <p className='group_description'>
                                                    {obj.description}
                                                </p>
                                            </div>

                                            <button
                                                className='group_unjoin_us'
                                                onClick={e => handleUnfollow(e, obj.id)}>
                                                Unfollow
                                            </button>
                                        </div>
                                    </Link>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}