import React, {useEffect, useState} from "react";
import "./EarlyUsers.css";
import Sidebar from "../../Elements/Sidebar/Sidebar";
import ActiveThreads from "../../Elements/ActiveThreads/ActiveThreads";
import makeRequest from "../../Elements/makeRequest";
import reportIWBHError from "../../Elements/reportError";

export default function EarlyUsers() {
    const [user, setUser] = useState({})
    const [active, setActive] = useState(false)

    useEffect(() => {
        async function fetchData() {
            await makeRequest('api/user/data', 'POST', {"token": localStorage.getItem('token')})
                .then(data => {
                    if (data.error) {
                        reportIWBHError(data.error)
                    } else {
                        setUser(data.user)
                        setActive(true)
                    }
                })
        }
        fetchData();
    }, []);

    return (
        <div className="submit__page">
            <Sidebar />
            <div className="submit__page_content">
                <h1>
                    Here we will list updates and our plans on this website
                </h1>
                <h2 className="subtitle">
                    Including your ideas!
                </h2>

                <h2>Our plans:</h2>

                <div className="our__plans">
                    <ol className="plans">

                        <li className="plan_element">
                            Create forums (knowledge base), where you can ask a question about
                            <span className="category"> running</span>,
                            <span className="category"> supplements</span>,
                            <span className="category"> history</span>,
                            <span className="category"> startups, finances</span>,
                            anything that relates to &nbsp;
                            <span className="category">
                                self-improvement
                            </span>, and &nbsp;
                            <span className="category">
                                science
                            </span>
                        </li>

                        {/*<li className="plan_element">*/}
                        {/*    Add &nbsp;*/}
                        {/*    <span className="category">*/}
                        {/*        daily challenge*/}
                        {/*    </span>, and daily thread with photos of*/}
                        {/*    participants*/}
                        {/*</li>*/}

                        <li className="plan_element">
                            Create an interesting &nbsp;
                            <span className="category">
                                source of information.</span> <br/>
                            We know that you guys have a lot of interests, and usual newsletters are not so interesting.
                            We would like to create a &nbsp;
                            <span className="category">
                                platform
                                </span> which will be both useful and interesting to read, listen.
                            For example, with &nbsp;
                            <span className="category">
                                daily short video
                            </span> or translation with
                            <span className="category">&nbsp;
                                news from different areas. </span>
                        </li>

                        <li className="plan_element">
                            Update design
                        </li>

                        <li className="plan_element">
                            Mobile app
                        </li>

                        <li className="plan_element">
                            Create a feature for users to design their own profiles.
                            <br />
                            Design a profile that reflects your personality and interests!
                            (via customizable background images, videos and different sections)
                            <br />
                        </li>

                        <li>
                            Add achievements
                        </li>

                        <li className='plan_element'>
                            Listen to our users and continuously improve
                        </li>
                    </ol>
                </div>
            </div>

            {active ? <ActiveThreads u={user}/> : <></>}
        </div>
    )
}