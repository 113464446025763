import React, { useState, useEffect} from 'react';
import './CreateGroupPost.css';
import './Image.css';
import Sidebar from "../../Elements/Sidebar/Sidebar";
import {initializeApp} from "firebase/app";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import makeRequest from "../../Elements/makeRequest";
import {Multiselect} from "multiselect-react-dropdown";
import {useParams} from "react-router-dom";

export default function CreateGroupPost() {
    let toolTipData;
    let uploadedFileIconText;
    let uploadArea;
    let dropZoon
    let loadingText;
    let fileInput;
    let previewImage;
    let fileDetails;
    let uploadedFile;
    let uploadedFileInfo;
    let uploadedFileName;
    let uploadedFileCounter;
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SERVER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };

    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [imgUrl, setImgUrl] = useState('');
    const [selected, setSelected] = useState([]);

    let params = useParams()

    // Images Types
    const imagesTypes = [
        "jpeg",
        "png",
        "svg",
        "gif"
    ];

    useEffect(() => {
        async function fetchTags() {
            await makeRequest("api/post/tags", 'GET')
                .then(data => {
                    if (data.error) {
                        reportError(data.error)
                        alert('Something went wrong... Error reported')
                        // changeErrorText(data.error)
                    } else {
                        setTags(data.tags);
                    }
                })
        }
        fetchTags()

        toolTipData =  document.querySelector('.upload-area__tooltip-data');
        dropZoon  = document.querySelector('#dropZoon');
        fileInput  = document.querySelector('#fileInput');
        uploadedFileIconText = document.querySelector('.uploaded-file__icon-text');
        previewImage  = document.querySelector('#previewImage');
        fileDetails  = document.querySelector('#fileDetails');
        uploadedFile  = document.querySelector('#uploadedFile');
        uploadedFileInfo = document.querySelector('#uploadedFileInfo');
        uploadedFileName  = document.querySelector('.uploaded-file__name');
        uploadedFileCounter  = document.querySelector('.uploaded-file__counter');
        loadingText  = document.querySelector('#loadingText');
        uploadArea  = document.querySelector('#uploadArea')

        // Append Images Types Array Inisde Tooltip Data
        toolTipData.innerHTML = [...imagesTypes].join(', .');

        // When (drop-zoon) has (dragover) Event
        dropZoon.addEventListener('dragover', function (event) {
            event.preventDefault();

            // Add Class (drop-zoon--over) On (drop-zoon)
            dropZoon.classList.add('drop-zoon--over');
        });

        // When (drop-zoon) has (dragleave) Event
        dropZoon.addEventListener('dragleave', function (event) {
            // Remove Class (drop-zoon--over) from (drop-zoon)
            dropZoon.classList.remove('drop-zoon--over');
        });

        // When (drop-zoon) has (drop) Event
        dropZoon.addEventListener('drop', function (event) {
            // Prevent Default Behavior
            event.preventDefault();

            // Remove Class (drop-zoon--over) from (drop-zoon)
            dropZoon.classList.remove('drop-zoon--over');

            // Select The Dropped File
            const file = event.dataTransfer.files[0];

            // Call Function uploadFile(), And Send To Her The Dropped File :)
            uploadFile(file);
        });

        // When (drop-zoon) has (click) Event
        dropZoon.addEventListener('click', function (event) {
            // Click The (fileInput)
            fileInput.click();
        });

        // When (fileInput) has (change) Event
        fileInput.addEventListener('change', function (event) {
            // Select The Chosen File
            const file = event.target.files[0];

            // Call Function uploadFile(), And Send To Her The Chosen File :)
            uploadFile(file);
        });
    }, []);

    function uploadFile(file) {
        // FileReader()
        const fileReader = new FileReader();
        // File Type
        const fileType = file.type;
        // File Size
        const fileSize = file.size;

        // If File Is Passed from the (File Validation) Function
        if (fileValidate(fileType, fileSize)) {
            // Add Class (drop-zoon--Uploaded) on (drop-zoon)
            dropZoon.classList.add('drop-zoon--Uploaded');

            // Show Loading-text
            loadingText.style.display = "block";
            // Hide Preview Image
            previewImage.style.display = 'none';

            // Remove Class (uploaded-file--open) From (uploadedFile)
            uploadedFile.classList.remove('uploaded-file--open');
            // Remove Class (uploaded-file__info--active) from (uploadedFileInfo)
            uploadedFileInfo.classList.remove('uploaded-file__info--active');

            // After File Reader Loaded
            fileReader.addEventListener('load', function () {
                // After Half Second
                setTimeout(function () {
                    // Add Class (upload-area--open) On (uploadArea)
                    uploadArea.classList.add('upload-area--open');

                    // Hide Loading-text (please-wait) Element
                    loadingText.style.display = "none";
                    // Show Preview Image
                    previewImage.style.display = 'block';

                    // Add Class (file-details--open) On (fileDetails)
                    fileDetails.classList.add('file-details--open');
                    // Add Class (uploaded-file--open) On (uploadedFile)
                    uploadedFile.classList.add('uploaded-file--open');
                    // Add Class (uploaded-file__info--active) On (uploadedFileInfo)
                    uploadedFileInfo.classList.add('uploaded-file__info--active');
                }, 500); // 0.5s

                // Add The (fileReader) Result Inside (previewImage) Source
                previewImage.setAttribute('src', fileReader.result);

                // Add File Name Inside Uploaded File Name
                uploadedFileName.innerHTML = file.name;

                // Call Function progressMove();
                progressMove();
            });

            // Read (file) As Data Url
            fileReader.readAsDataURL(file);
        } else { // Else

            fileValidate(fileType, fileSize); // (this) Represent The fileValidate(fileType, fileSize) Function

        };
    };

    function progressMove() {
        let counter = 0;

        setTimeout(() => {
            let counterIncrease = setInterval(() => {
                if (counter === 100) {
                    clearInterval(counterIncrease);
                } else { // Else
                    counter = counter + 10;
                    uploadedFileCounter.innerHTML = `${counter}%`
                }
            }, 100);
        }, 600);
    };

    function fileValidate(fileType, fileSize) {
        // File Type Validation
        let isImage = imagesTypes.filter((type) => fileType.indexOf(`image/${type}`) !== -1);

        // If The Uploaded File Type Is 'jpeg'
        if (isImage[0] === 'jpeg') {
            // Add Inisde (uploadedFileIconText) The (jpg) Value
            uploadedFileIconText.innerHTML = 'jpg';
        } else { // else
            // Add Inisde (uploadedFileIconText) The Uploaded File Type
            uploadedFileIconText.innerHTML = isImage[0];
        };

        // If The Uploaded File Is An Image
        if (isImage.length !== 0) {
            // Check, If File Size Is 2MB or Less
            if (fileSize <= 2000000) { // 2MB :)
                return true;
            } else { // Else File Size
                return alert('Please Your File Should be 2 Megabytes or Less');
            };
        } else { // Else File Type
            return alert('Please make sure to upload An Image File Type');
        };
    };

    const displayError = (message) => {
        let error = document.getElementById('error')
        error.innerText = message;
        error.style.display = 'block';
    }

    const displaySuccess = (message) => {
        let success = document.getElementById('success')
        success.innerText = message;
        success.style.display = 'block';
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const file = e.target[0]?.files[0]

        if (file) {
            const storageRef = ref(storage, `groups/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed",
                (snapshot) => {
                    console.log('Uploading group main photo file...')
                },
                (error) => {
                    displayError(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setImgUrl(downloadURL)
                    });
                }
            );
        }

        uploadData()
    }

    async function uploadData() {
        await makeRequest('api/groups/' + params.id.toString() + '/posts/create', 'POST', {
        "post": {
            'title':title,
            'description':description,
            'photo_url':imgUrl,
        },
        'token': localStorage.getItem('token')
    })
            .then(data => {
                if (data.error) {
                    displayError(data.error)
                } else {
                    displaySuccess(data.message)
                }
            })
    }

    return (
        <div className='create_group'>
            <Sidebar />

            <div className='create_group_feed'>
                <div id='error' className='error'></div>
                <div id='success' className='success'></div>
                <form className='create_group_form' onSubmit={(e) => handleSubmit(e)}>
                    <h1 className='create_group_form_title'>Group post creation form</h1>
                    <div id="uploadArea" className="upload-area">
                        <div className="upload-area__header">
                            <h1 className="upload-area__title">Upload Group Photo</h1>
                            <p className="upload-area__paragraph">
                                File should be an image
                                <strong className="upload-area__tooltip">
                                    &nbsp; Like
                                    <span className="upload-area__tooltip-data"></span>
                                </strong>
                            </p>
                        </div>

                        <div id="dropZoon" className="upload-area__drop-zoon drop-zoon">
                            <span className="drop-zoon__icon">
                              <i className='bx bxs-file-image'></i>
                            </span>
                            <p className="drop-zoon__paragraph">Drop your file here or Click to browse</p>

                            <span id="loadingText" className="drop-zoon__loading-text">Please Wait</span>
                            <img
                                src="" alt="Preview Image" id="previewImage" className="drop-zoon__preview-image"
                                draggable="false"/>
                            <input
                                type="file"
                                id="fileInput"
                                className="drop-zoon__file-input" accept="image/*"
                                required={true}
                            />
                        </div>

                        <div id="fileDetails" className="upload-area__file-details file-details">
                            <h3 className="file-details__title">Uploaded File</h3>

                            <div id="uploadedFile" className="uploaded-file">
                                <div className="uploaded-file__icon-container">
                                    <i className='bx bxs-file-blank uploaded-file__icon'></i>
                                    <span className="uploaded-file__icon-text"></span>
                                </div>

                                <div id="uploadedFileInfo" className="uploaded-file__info">
                                    <span className="uploaded-file__name">Proejct 1</span>
                                    <span className="uploaded-file__counter">0%</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        type='text' className='group__title_field' id='group__title'
                        placeholder='Group title'
                        required={true}
                    />

                    <input type='text'
                           value={description}
                           onChange={(e) => setDescription(e.target.value)}
                           className='group__title_field' id='group__title'
                           placeholder='Describe your group'
                           required={true}
                    />

                    <div className="multi__select_field field">
                        <label style={{color: 'black'}} htmlFor="text__input">
                            Categories associated with Your post:
                        </label>
                        <Multiselect
                            className="multi__select"
                            isObject={true}
                            onKeyPressFn={() => {
                            }}
                            onRemove={(selectedList, removeItem) => {
                                setSelected(selected.filter((val) => val !== removeItem));
                            }}
                            onSearch={() => {
                            }}
                            onSelect={(selected, selectedItem) => {
                                setSelected([...selected, selectedItem]);
                            }}
                            options={tags}
                            displayValue='tag'
                        />
                    </div>

                    <button type='submit' className='create_group_submit'>
                        Create community
                    </button>
                </form>
            </div>
        </div>
    )
}