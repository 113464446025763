import React, {useEffect, useState} from 'react';
import './ShowUsersPosts.css';
import Sidebar from "../../Elements/Sidebar/Sidebar";
import {Link, useParams} from "react-router-dom";
import Post from "../../Elements/Post/Post";
import {FaLongArrowAltDown, FaLongArrowAltUp} from "react-icons/fa";
import SmallPostCard from "../../Elements/Post/SmallPostCard";
import makeRequest from "../../Elements/makeRequest";

export default function ShowUserPosts() {
    let params = useParams();

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        async function fetchPosts() {
            await makeRequest('api/user/' + params.id.toString() + '/posts', 'GET')
                .then(data => {
                    setPosts(data.posts)
                })
        }

        fetchPosts()
    }, []);

    return (
        <div className="settings_page">
            <Sidebar/>

            <div className="profile_feed">
                <div className="nav_section">
                    <ul className="navigation">
                        <li className="nav_element">
                            <Link to={"/profile/users/" + params.id}>
                                User's Profile
                            </Link>
                        </li>

                        <li className="nav_element">
                            <Link to={"/profile/users/" + params.id + "/posts"}>
                                Posts
                            </Link>
                        </li>

                        <li className="nav_element">
                            <Link to={"/profile/users/" + params.id + "/comments"}>
                                Comments
                            </Link>
                        </li>

                        <li className="nav_element">
                            <Link to={"/profile/users/" + params.id + "/settings"}>
                                Settings
                            </Link>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    )
}