import React, {useEffect, useState} from "react";
import "./Post.css";
import {useParams} from "react-router-dom";
import {CiHeart} from "react-icons/ci";
import {VscComment} from "react-icons/vsc";
import heart from '../../Images/Heart.png';
import comment from '../../Images/Comment.png';
import reportIWBHError from "../reportError";

export default function Post({ id, title, description, tags,
                                 postAuthor, upvotesAmount, comments, rep }) {
    const refreshPost = () => {
        // make new request to server and refresh upvotes amount
        // TODO: create handler on server to refresh post stats
    }

    const setActiveUpvoteButton = (index) => {
        let upvoteButton = document.getElementById('button_upvote_'+id.toString());
        let downvoteButton = document.getElementById('button_downvote_'+id.toString());
        downvoteButton.style.background = 'lightgrey';
        downvoteButton.style.color = 'grey';
        upvoteButton.style.background = '#3D52D5';
        upvoteButton.style.color = '#FBFFF1';
    }

    const setActiveDownvoteButton = (index) => {
        let upvoteButton = document.getElementById('button_upvote_'+id.toString());
        let downvoteButton = document.getElementById('button_downvote_'+id.toString());
        upvoteButton.style.background = 'lightgrey';
        upvoteButton.style.color = 'grey';
        downvoteButton.style.background = '#3D52D5';
        downvoteButton.style.color = '#FBFFF1';
    }

    const clearUpvoteButton = () => {
        let upvoteButton = document.getElementById('button_upvote_'+id.toString());
        upvoteButton.style.background =  'rgba(154, 255, 0, 0.5)';
        upvoteButton.style.color = 'grey';
    }

    const clearDownvoteButton = () => {
        let downvoteButton = document.getElementById('button_downvote_'+id.toString());
        downvoteButton.style.background = 'crimson';
        downvoteButton.style.color = 'grey';
    }

    return (
        <div className="post">
            <div className="post_top">
                <div className="post__author">
                    <img className="author_photo"
                         src={postAuthor.photo_url ? postAuthor.photo_url : "https://cdn.pfps.gg/pfps/1111-husky-2.png"}
                         alt={"author " + postAuthor.username}/>
                    <p>{postAuthor.username}</p>
                </div>
            </div>
            <h2 style={{marginLeft: '0px'}} className="post__title">{title}</h2>
            <p className="post_description">
                {description}
            </p>
            <div className="bottom_content">
                <div className="tags">
                    {tags && tags.map((obj, index) => {
                        if (index >= 7) {
                            return <></>
                        } else {
                            return (
                                <div style={{marginLeft: '5px'}} key={index} className={"tag " + obj.tag}>
                                    #{obj.tag}
                                </div>
                            )
                        }
                    })}
                </div>

            </div>

            <div className="post_reputation">
                <div className="heart">
                    <img src={heart} alt='heart' />
                    {upvotesAmount}
                </div>

                <div className="comment_ic">
                    <img src={comment} alt='comment' />
                    {comments ? comments.length : 0}
                </div>
            </div>
        </div>
    )
}