import React from 'react';
import Sidebar from "../../Elements/Sidebar/Sidebar";

export default function ComingSoon() {
    return (
        <div className='coming_soon'>
            <Sidebar />
            <div className='coming_soon_body'>
                <h1 style={{color: 'white'}}>Coming soon...</h1>
            </div>
        </div>
    )
}