import React, {useEffect, useState} from 'react';
import "./Profile.css";
import Sidebar from "../../Elements/Sidebar/Sidebar";
import photo from "../../Images/profile-photo.jpg";
import {GiBoxingGlove} from "react-icons/gi";
import {FaCalendar, FaLongArrowAltDown, FaLongArrowAltUp} from "react-icons/fa";
import {Link, redirect, useParams} from "react-router-dom";
import makeRequest from "../../Elements/makeRequest";
import SmallPostCard from "../../Elements/Post/SmallPostCard";
import Post from "../../Elements/Post/Post";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {initializeApp} from "firebase/app";
import reportIWBHError from "../../Elements/reportError";

export default function Profile() {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SERVER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };
    let params = useParams();

    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);

    // Fields for updating user information
    const [imgUrl, setImgUrl] = useState(null);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [description, setDescription] = useState('');

    // User-related information:
    // Posts, comments
    const [author, setAuthor] = useState({})
    const [comments, setComments] = useState([]);
    const [posts, setPosts] = useState([]);
    const [active, setIsActive] = useState(0);
    const [progresspercent, setProgresspercent] = useState(0);
    const [followed, setFollowed] = useState(false);
    const [user, setUser] = useState({});
    const [followers, setFollowers] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);

    async function fetchAuthor() {
        await makeRequest('api/user/'+params.id.toString(), 'GET')
            .then(data => {
                setAuthor(data.user)
            })
    }

    async function fetchUser() {
        await makeRequest('api/user/data', 'POST', {
            'token': localStorage.getItem('token')
        })
            .then(data => {
                setUser(data.user)
            })
    }

    useEffect(() => {
        if (localStorage.getItem('token') == null)
        {
            window.location.href = "https://www.iwbh.me/#/login";
            return;
        }

        fetchUser()
        fetchAuthor()

        async function fetchUserPosts() {
            await makeRequest('api/user/'+params.id.toString() + '/posts', 'GET')
                .then(data => {
                    setPosts(data.posts)
                })
        }

        async function fetchUserFollowers() {
            await makeRequest('api/user/'+params.id.toString() + '/followers', 'GET')
                .then(data => {
                    setFollowers(data.users);

                    if (followers.filter((val) => val.id === user.id).length > 0) {
                        setFollowed(true);
                    } else {
                        setFollowed(false);
                    }

                })
        }

        async function fetchUserSubscriptions() {
            await makeRequest('api/user/'+params.id.toString() + '/subscriptions', 'GET')
                .then(data => {
                    setSubscriptions(data.users)
                })
        }

        async function fetchUserComments() {
            await makeRequest('api/user/'+params.id.toString() + '/comments', 'GET')
                .then(data => {
                    setComments(data.comments)
                })
        }

        fetchUserFollowers()
        fetchUserSubscriptions()
        fetchUserPosts()
        fetchUserComments()
        // console.log(user.id, params.id)
    }, []);

    const handleFollow = () => {
        async function follow() {
            await makeRequest('api/user/follow/' + params.id.toString(), 'POST', {
                'token': localStorage.getItem('token')
            }).then(data => {
                if (data.error) {
                    if (data.error === "you have already subscribed to this person") {
                        setFollowed(true)
                    } else {
                        displayError(data.error)
                    }
                } else {
                    displaySuccess(data.message)
                    setFollowed(true)
                }
            })
        }

        follow()
    }

    const handleUnfollow = () => {
        async function unfollow() {
            await makeRequest('api/user/unfollow/' + params.id.toString(), 'POST', {
                'token': localStorage.getItem('token')
            }).then(data => {
                if (data.error) {
                    displayError(data.error)
                } else {
                    displaySuccess(data.message)
                    setFollowed(false)
                    setFollowers(followers.filter((val) => val.id !== user.id))
                }
            })
        }

        unfollow()
    }

    const displayError = (message) => {
        let error = document.getElementById('error')
        error.style.display = 'block'
        error.innerText = message
    }

    const displaySuccess = (message) => {
        let success = document.getElementById('success')
        success.style.display = 'block'
        success.innerText = message
    }

    async function uploadData() {
        await makeRequest(`api/user/${params.id.toString()}/update`, 'PUT', {
            "user": {
                'id' : parseInt(params.id),
                'firstname': firstname,
                'lastname': lastname,
                'email': email,
                'description': description,
                'username': username,
                'photo_url': imgUrl,
            },
            'token': localStorage.getItem('token'),
        })}

    const handleSubmit = (e) => {
        e.preventDefault()
        const file = e.target[0]?.files[0]

        if (file) {
            const storageRef = ref(storage, `files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    reportIWBHError(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setImgUrl(downloadURL)
                    });
                }
            );
        }
        uploadData()
    }

    return (
        <div className="settings_page">
            <Sidebar/>

                <div className="settings_feed">
                    <div className='error' id='error'></div>
                    <div className='success' id='success'></div>

                    <div className="profile_section">
                        <div className="profile">
                            <img
                                className="profile__photo"
                                src={author.photo_url == 'no_photo' ? "https://cdn.pfps.gg/pfps/1111-husky-2.png" : author.photo_url}
                                alt="your_profile_photo"/>

                            <div className="profile_info">
                                <div style={{marginTop: '10px'}}
                                     className="username_and_points">
                                    <h2 style={{margin: '0'}} className="username">
                                        {author.username} | Points: {author.reputation}
                                    </h2>

                                    {author.firstname && author.lastname ?
                                        <h2 style={{margin: '0'}}>
                                            {author.firstname} {author.lastname}
                                        </h2> : <></>
                                    }
                                </div>


                                <p className="description">
                                    {author.description}
                                </p>

                                {user.id == params.id ? <></> : <button className='follow_button' onClick={followed ? handleUnfollow : handleFollow}>
                                    {followed ? "Unsubscribe" : "Subscribe"}
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>

            <div className="nav_section">
                <ul className="navigation">
                    <li className={active == 0 ? "active_nav_element" : "nav_element"}>
                        <Link onClick={e => setIsActive(0)}>
                            Posts
                        </Link>
                    </li>

                    <li className={active == 1 ? "active_nav_element" : "nav_element"}>
                        <Link onClick={e => setIsActive(1)}>
                            Comments
                        </Link>
                    </li>

                    <li className={active == 3 ? "active_nav_element" : "nav_element"}>
                        <Link onClick={e => setIsActive(3)}>
                            Followers
                        </Link>
                    </li>

                    <li className={active == 4 ? "active_nav_element" : "nav_element"}>
                        <Link onClick={e => setIsActive(4)}>
                            Subscriptions
                        </Link>
                    </li>

                    {user.id == author.id ?
                    <li className={active == 2 ? "active_nav_element" : "nav_element"}>
                        <Link onClick={e => setIsActive(2)}>
                            Update Settings
                        </Link>
                    </li>
                            : <></>
                    }
                </ul>
            </div>

            {active == 0 ?
                <div className="posts_section">
                    <div className="posts_and_stats">
                        {posts && posts.map((obj, index) => {
                            return (
                                <Link
                                    style={{width: '50%'}}
                                    className='profile_post'
                                    to={"/posts/" + obj.id} key={index}>
                                    <Post
                                          index={index}
                                          id={obj.id}
                                          title={obj.title}
                                          tags={obj.tags}
                                          upvotesAmount={obj.upvotes_amount}
                                          postAuthor={author}
                                          description={obj.description}
                                          comments={obj.comments}
                                          rep={obj.rep}
                                    />
                                </Link>
                            )
                        })}
                    </div>
                </div>

                : <></>}

            {active == 1 ?
                <div className="posts_section">
                    <div className="user_comments">
                        {comments && comments.map((obj, index) => {
                            return (
                                <Link
                                    style={{width: '50%'}}
                                    className='profile_post'
                                    to={"/posts/" + obj.post_id} key={index}>

                                    <details key={obj.id} open className="comment" style={{marginLeft: `${30}px`, width: '50%'}}>
                                        <summary>
                                            <div className="comment-heading">
                                                <div className="comment-info">
                                                    <span
                                                        className="comment-author">{author.username}, &nbsp;</span>
                                                    <span
                                                        className="comment_subtitle">{author.firstname} {author.lastname} | </span>
                                                    <p className="m-0">{obj.votes ? obj.votes : 1} points &bull; {String(obj.created_at).substring(0, 10)}</p>
                                                </div>
                                            </div>
                                        </summary>
                                        <div className="comment-body">
                                            <p>{obj.content}</p>
                                        </div>
                                    </details>

                                    {/*<Post*/}
                                    {/*    index={index}*/}
                                    {/*    id={obj.id}*/}
                                    {/*    title={obj.title}*/}
                                    {/*    postAuthor={author}*/}
                                    {/*    description={obj.content}*/}
                                    {/*/>*/}
                                </Link>
                            )
                        })}
                    </div>
                </div>
                : <></>}

            {/*
                Display user followers
            */}
            {active == 3 ?
                <div className="posts_section">
                    <div className="user_comments">
                        {followers && followers.map((follower, index) => {
                            return (
                                <a
                                    style={{width: '50%'}}
                                    className='profile_post'
                                    href={"/#/profile/users/" + follower.id} key={index}
                                >

                                    <div className="user_profile">
                                        <img
                                            className="profile__photo"
                                            src={follower.photo_url == 'no_photo' ? "https://cdn.pfps.gg/pfps/1111-husky-2.png" : follower.photo_url}
                                            alt="your_profile_photo"/>

                                        <div className="profile_info">
                                            <div style={{marginTop: '10px'}}
                                                 className="username_and_points">
                                                <h2 style={{margin: '0'}} className="username">
                                                    {follower.username} | {follower.reputation}
                                                </h2>

                                                {follower.firstname && follower.lastname ?
                                                    <h2 style={{margin: '0'}}>
                                                        {follower.firstname} {follower.lastname}
                                                    </h2> : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {/*<Post*/}
                                    {/*    index={index}*/}
                                    {/*    id={obj.id}*/}
                                    {/*    title={obj.title}*/}
                                    {/*    postAuthor={author}*/}
                                    {/*    description={obj.content}*/}
                                    {/*/>*/}
                                </a>
                            )
                        })}
                    </div>
                </div>
                : <></>}

            {/*
                Display user subscriptions
            */}
            {active == 4 ?
                <div className="posts_section">
                    <div className="user_comments">
                        {subscriptions && subscriptions.map((subscription, index) => {
                            return (
                                <a
                                    style={{width: '50%'}}
                                    className='profile_post'
                                    href={"/#/profile/users/" + subscription.id} key={index}
                                >

                                    <div className="user_profile">
                                        <img
                                            className="profile__photo"
                                            src={subscription.photo_url == 'no_photo' ? "https://cdn.pfps.gg/pfps/1111-husky-2.png" : subscription.photo_url}
                                            alt="your_profile_photo"/>

                                        <div className="profile_info">
                                            <div style={{marginTop: '10px'}}
                                                 className="username_and_points">
                                                <h2 style={{margin: '0'}} className="username">
                                                    {subscription.username} | {subscription.reputation}
                                                </h2>

                                                {subscription.firstname && subscription.lastname ?
                                                    <h2 style={{margin: '0'}}>
                                                        {subscription.firstname} {subscription.lastname}
                                                    </h2> : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {/*<Post*/}
                                    {/*    index={index}*/}
                                    {/*    id={obj.id}*/}
                                    {/*    title={obj.title}*/}
                                    {/*    postAuthor={author}*/}
                                    {/*    description={obj.content}*/}
                                    {/*/>*/}
                                </a>
                            )
                        })}
                    </div>
                </div>
                : <></>}


            {user.id === parseInt(params.id) && active === 2 && (
                <div className="settings_section">
                    <div className="settings_body">
                        <form onSubmit={handleSubmit} className='form'>
                            <h2 style={{margin: '0', padding: '0', color: 'white', fontWeight: '300'}}>Edit Your Profile</h2>

                            <div className="file-input">
                                <input type="file" id="file" className="file"/>
                                <label htmlFor="file">
                                    Change profile photo
                                    <p className="file-name"></p>
                                </label>
                            </div>

                            <div className='edit_field'>
                                <label className='edit_label'>Username</label>
                                <input
                                    type='text'
                                    className="settings_inp"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                    placeholder='New username'
                                />
                            </div>

                            <div className='edit_field'>
                                <label className='edit_label'>Profile Description</label>
                                <textarea
                                    rows={4}
                                    type='text'
                                    className="settings_inp"
                                    value={description}
                                    onChange={e => setDescription(e.target.value)}
                                    placeholder='New description'
                                />
                            </div>

                            <div className='edit_field'>
                                <label className='edit_label'>Email</label>
                                <input
                                    type='text'
                                    className="settings_inp"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder='New email'
                                />
                            </div>

                            <div className='edit_field'>
                                <label className='edit_label'>Firstname</label>
                                <input
                                    type='text'
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    className="settings_inp"
                                    placeholder='New firstname'
                                />
                            </div>

                            <div className='edit_field'>
                                <label className='edit_label'>Lastname</label>
                                <input
                                    type='text'
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                    className="settings_inp"
                                    placeholder='New lastname'
                                />
                            </div>

                            <a style={{marginTop: '15px', textDecoration: 'none', color: 'white'}}
                               href={'/#/user/' + params.id.toString() + '/change-interests'}>
                                Change My Interests
                            </a>

                            <button className='submit_settings' type='submit'>Update my information</button>
                        </form>
                        {!imgUrl && (
                            <div className='outerbar'>
                                <div className='innerbar'
                                     style={{
                                         backgroundColor: 'limegreen',
                                         borderRadius: '6px',
                                         marginTop: '10px',
                                         color: 'black',
                                         width: `${progresspercent}%`
                                     }}>
                                    {progresspercent}%
                                </div>
                            </div>
                        )}
                        {imgUrl && <img src={imgUrl} alt='uploaded file' style={{marginLeft: '150px'}} height={200}/>}
                    </div>
                </div>
            )}

        </div>
    )
}