import React, {useEffect, useState} from 'react';
import './ValidationPostDetailed.css';
import makeRequest from "../../Elements/makeRequest";
import {useParams} from "react-router-dom";
import {FaBook, FaHistory, FaRegCompass, FaWindows} from "react-icons/fa";
import {CiGrid42} from "react-icons/ci";
import {SiGoogleanalytics} from "react-icons/si";
import {GiPieChart} from "react-icons/gi";
import {IoIosSettings} from "react-icons/io";

export default function ValidationPostDetailed() {
    const [obj, setObj] = useState({});
    let params = useParams();

    useEffect(() => {
        async function fetchValidationPost() {
            await makeRequest('admin/validation-post/'+ params.id.toString(), 'POST', {
                'token': localStorage.getItem('token')
            })
                .then(data => {
                    setObj(data.post)
                })
        }
        fetchValidationPost()
    }, []);

    const validatePost = () => {
        async function validatePost() {
            await makeRequest('admin/validate-post/' + params.id.toString(), 'POST', {
                'token': localStorage.getItem('token')
            })
                .then(data => {
                    if (data.error) {
                        document.getElementById('success_body').innerText = data.error;
                        document.getElementById('success_body').style.background = 'crimson';
                    } else {
                        document.getElementById('success_body').innerText = 'Yay! One post uploaded :)';
                        document.getElementById('queue_post_status').innerText = 'On the way';
                        document.getElementById('queue_post_status').background = 'rgb(212,255,217)';
                    }
                })
        }
        validatePost()
    }

    return (
        <div className="validation_post_detailed">
            <div className="admin_sidebar">
                <div className="logo-details">
                    <span style={{padding: '5px 15px'}} className="logo_name">IWBH</span>
                </div>

                <ul className="nav-links">
                    <li>
                        <div className="icon-link">
                            <a href="/#/admin">
                                <FaWindows/>
                                <span className="link_name">Dashboard</span>
                            </a>
                            <i className='bx bx-grid-alt'></i>
                        </div>
                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="#">Category</a></li>
                        </ul>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <CiGrid42/>
                                <span className="link_name">Category</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>
                        <ul className="sub-menu">
                            <li><a className="link_name" href="#">Category</a></li>
                            <li><a href="#">HTML & CSS</a></li>
                            <li><a href="#">JavaScript</a></li>
                            <li><a href="#">PHP & MySQL</a></li>
                        </ul>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <FaBook/>
                                <span className="link_name">Posts</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>

                        <ul className="sub-menu">
                            <li><a className="link_name" href="#">Posts</a></li>
                            <li><a href="#">Web Design</a></li>
                            <li><a href="#">Login Form</a></li>
                            <li><a href="#">Card Design</a></li>
                        </ul>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <SiGoogleanalytics/>
                                <span className="link_name">Analytics</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>

                        <ul className="sub-menu">
                            <li><a className="link_name" href="#">Analytics</a></li>
                        </ul>
                    </li>

                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <GiPieChart/>
                                <span className="link_name">Chart</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>

                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="#">Chart</a></li>
                        </ul>
                    </li>

                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <FaRegCompass/>
                                <span className="link_name">Explore</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>

                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="#">Explore</a></li>
                        </ul>
                    </li>

                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <FaHistory/>
                                <span className="link_name">History</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>

                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="#">History</a></li>
                        </ul>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <IoIosSettings/>
                                <span className="link_name">Setting</span>
                            </a>
                            <i className='bx bxs-chevron-down arrow'></i>
                        </div>
                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="#">Setting</a></li>
                        </ul>
                    </li>
                    <li>
                        <div className="profile-details">
                            <div className="profile-content">
                                <img src="http://placebeard.it/250/250" alt="profileImg"/>
                            </div>
                            <div className="name-job">
                                <div className="profile_name">@Qniwwwersss</div>
                                <div className="job">Software Engineer</div>
                            </div>
                            <i className='bx bx-log-out'></i>
                        </div>
                    </li>
                </ul>
            </div>


            <div id="success_body" className="success_body">Heello</div>
            {obj ? <div className="queue_post">
                <h3 className="queue_post_title">
                    {obj.title}
                </h3>
                <p className="queue_post_description detailed">
                    {obj.description}
                </p>

                <div id="queue_post_status" className="queue_post_status">
                    Pending
                </div>

                <p className="queue_post_timestamp">
                    {String(obj.created_at).substring(0, 10)}
                </p>

                <button onClick={validatePost} className="queue_post_validate">
                    Validate
                </button>
            </div> : <></>}
        </div>
    )
}