import React, {useEffect, useState} from "react";
import './DetailedPost.css';
import {GiBoxingGlove} from "react-icons/gi";
import makeRequest from "../makeRequest";
import CommentsSection from "../Comments/CommentsSection";
import heart from "../../Images/Heart.png";
import comment from "../../Images/Comment.png";
import reportIWBHError from "../reportError";

export default function DetailedPost({ id }) {
    // post id
    const [post, setPost] = useState({});
    const [author, setAuthor] = useState({});
    const [active, setIsActive] = useState(false);
    const [comments, setComments] = useState([]);
    // const [isActive, setIsActive] = useState(true)

    const [showPost, setShowPost] = useState(false)
    const [isUpvote, setIsUpvote] = useState(2)


    useEffect(() => {
        async function fetchComments() {
            await makeRequest('api/post/' + id.toString() + '/comments', 'GET')
                .then(data => {
                    setComments(data.comments)
                })
        }
        async function fetchPost() {
            await makeRequest("api/post/" + id.toString(), 'GET')
                .then(data => {
                    if (data.error) {
                        window.location.href = '/#/login'
                    } else {
                        setPost(data.post)
                        setAuthor(data.post.author)
                        setShowPost(true)
                    }
                })
        }
        fetchPost()
        fetchComments()

        async function getUsersVoteDirection() {
            await makeRequest("api/post/" + id.toString() + "/vote-direction", 'POST', {
                "token": localStorage.getItem('token'),
            })
                .then(data => {
                    if (data.error) {
                        reportIWBHError(data.error)
                    } else {
                        setIsUpvote(data.isUpvote)
                    }
                })
        }
        getUsersVoteDirection()
    }, [])

    const handleUpvote = (active) => {
        async function Upvote() {
            await makeRequest("api/post/" + id.toString() + "/upvote", 'POST', {
                "token": localStorage.getItem('token'),
            })
                .then(data => {
                    if (data.error) {
                        reportIWBHError(data.error)
                    } else {
                        setActiveLike()
                    }
                })
        }
        async function Downvote() {
            await makeRequest("api/post/" + id.toString() + "/downvote", 'POST', {
                "token": localStorage.getItem('token'),
            })
                .then(data => {
                    if (data.error) {
                        reportIWBHError(data.error)
                    } else {
                        clearActiveLike()
                    }
                })
        }

        if (active == 1) {
            Downvote()
            setIsUpvote(0)
            let newPost = post;
            newPost.upvotes_amount -= 1
            setPost(newPost)
        } else {
            Upvote()
            setIsUpvote(1)
            let newPost = post;
            newPost.upvotes_amount += 1
            setPost(newPost)
        }
    }

    const setActiveLike = () => {
        let likeButton = document.getElementById("button_" + id.toString())
        likeButton.style.background = '#3D52D5';
    }

    const clearActiveLike = () => {
        let likeButton = document.getElementById("button_" + id.toString())
        likeButton.style.background = 'transparent';
    }

    return (
        <div className="deatiled_post_body">
            {showPost ?
                <div className="outer_post">
                    <div className="detailed_post_card">
                        <div className="detailed_post_header">
                            <h1 className="detailed_post__title">
                                {post.title}
                            </h1>

                            <div className="header_right">
                                <div className="images_and_username">
                                    <img
                                        src={author.photo_url ? author.photo_url : ""}
                                        alt="profile_photo"
                                        style={{width: "48px", height: "48px"}}
                                    />

                                    <div className="names">
                                        <p className="fullname">{author.fullname ? author.fullname : ""}</p>

                                        <a style={{color: 'white', textDecoration: 'none'}}
                                           href={'/#/profile/users/' + author.id.toString()}>
                                            <p className="username">{author.username ? "@" + author.username : ""}</p>
                                        </a>
                                    </div>
                                </div>

                                <div className="reputation">
                                    {author.reputation}
                                </div>
                            </div>
                        </div>

                        <p className="detailed_post__description">
                            {post.description}
                        </p>

                        <div className="detailed__post_tags">
                            {post.tags && post.tags.map((obj, ind) => {
                                return (
                                    <div key={ind} className="detailed_tag">
                                        #{obj.tag}
                                    </div>
                                )
                            })}
                        </div>

                        <div className="post_reputation">
                            <button
                                className={isUpvote == 1 ? "heart active" : 'heart'}
                                id={"button_" + id.toString()}
                                onClick={e => handleUpvote(isUpvote)}
                            >
                                <img src={heart} alt='heart'/>
                                {post.upvotes_amount}
                            </button>

                            <div className="comment_ic">
                                <img src={comment} alt='comment'/>
                                {comments ? comments.length : 0}
                            </div>
                        </div>
                    </div>

                    <div className="comments_section">
                        <div className='comments__section'>
                            <CommentsSection
                                postId={id}
                                initialComments={comments}
                            />
                        </div>
                    </div>
                </div> : <></>}
        </div>
    )
}
