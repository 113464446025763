import React, {useEffect} from "react";
import './DetailedPost.css';
import Sidebar from "../../Elements/Sidebar/Sidebar";
import Post from "../../Elements/Post/Post";
import {useParams} from "react-router-dom";
import DetailedPost from "../../Elements/DetailedPost/DetailedPost";

export default function DetailedPostPage() {
    let params = useParams();

    return (
        <div className="detailed_post">
            <Sidebar />

            <div className="deatiled_post_body">
                <DetailedPost
                   id={params.id} />
            </div>
        </div>
    )
}