import React, {useEffect, useState} from "react";
import Sidebar from "../../Elements/Sidebar/Sidebar";
import "./Homepage.css";
import Post from "../../Elements/Post/Post";
import ActiveThreads from "../../Elements/ActiveThreads/ActiveThreads";
import {Link} from "react-router-dom";
import makeRequest from "../../Elements/makeRequest";
import pen from '../../Images/Pen.png';
import Search from "../../Elements/SearchBar/Search";
import cross from '../../Images/white-cross.png';
import tick from '../../Images/post-buttons/tick.png';
import {Multiselect} from "multiselect-react-dropdown";
import reportIWBHError from "../../Elements/reportError";

export default function Homepage() {
    const [posts, setPosts] = useState([]);
    const [user, setUser] = useState({})
    const [active, setActive] = useState(false)
    const [showPosts, setShowPosts] = useState(false)
    const [showForm, setShowForm] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [tags, setTags] = useState([]);
    const [selected, setSelected] = useState([]);

    const getTitleLength = () => {
        return title.length;
    };

    // Function to dynamically set the class based on title length
    const getTitleClass = () => {
        return getTitleLength() > 30 ? 'too-long' : '';
    };

    useEffect(() => {
        async function fetchTags() {
            await makeRequest("api/post/tags", 'GET')
                .then(data => {
                    if (data.error) {
                        changeErrorText(data.error)
                    } else {
                        setTags(data.tags);
                    }
                })
        }
        fetchTags()

        async function fetchPosts() {
            await makeRequest("api/post/all?token=" + localStorage.getItem('token'), 'GET')
                .then(data => {
                    if (data.error) {
                        reportIWBHError(data.error)
                    } else {
                        setPosts(data.posts)
                        setShowPosts(true)
                        setTitle('')
                        setContent('')
                    }
                })
        }
        fetchPosts()

        async function fetchData() {
            await makeRequest('api/user/data', 'POST', {
                "token": localStorage.getItem('token')
            })
                .then(data => {
                    if (data.error) {
                        window.location.href = '/#/login'
                        return
                        // TODO: create better error handling
                    } else {
                        setUser(data.user)
                        setActive(true)
                    }
                })
        }
        fetchData();
    }, []);

    const renderPosts = () => {
        if (!posts || posts.length === 0) {
            return <></>;
        }

        return posts.map((obj, index) => {
            return (
                <Link
                    className='post_post'
                    to={"/posts/" + obj.id} key={index}>
                    <Post key={index}
                          index={index}
                          id={obj.id}
                          title={obj.title}
                          tags={obj.tags}
                          upvotesAmount={obj.upvotes_amount}
                          postAuthor={obj.author}
                          description={obj.description}
                          comments={obj.comments}
                          rep={obj.rep}
                    />
                </Link>
            )
        })
    }

    const handleSelect = (selected, selectedItem) => {
        if (selected.length <= 8) {
            setSelected([...selected, selectedItem]);
        } else {
            alert("Maximum of 8 tags allowed.");
            return;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        if (title.length > 255) {
            changeErrorText('Title should be less or equal to 255 chars.')
            return;
        }

        if (selected.length > 8) {
            changeErrorText('Maximum allowed amount of tags is 7. Please, remove some of them.')
            return;
        }

        try {
            async function pendValidation() {
                await makeRequest("api/post/pend-validation", 'POST',
                    {
                        "post": {
                            "title": title,
                            "description": content,
                            "tags": selected,
                        },
                        "token": localStorage.getItem('token')
                    })
                    .then(data => {
                        if (data.error) {
                            changeErrorText(data.error)
                        } else {
                            setShowForm(false)
                            changeSuccessText("Thank you! We will review it shortly, and get back to ya!")
                        }
                    })
            }
            pendValidation()
        } catch (e) {
            changeErrorText(e)
        }
    }

    const changeErrorText = (text) => {
        let err = document.getElementById("error_body")
        err.innerText = text
        err.style.backgroundColor = 'rgba(220, 20, 60, 0.9)';
        err.style.display = 'block'
    }

    const changeSuccessText = (text) => {
        let err = document.getElementById("error_body")
        err.style.display = 'block'
        err.innerText = text
        err.style.backgroundColor = "limegreen"
        err.style.color = 'black'
    }

    return (
        <div className="homepage">
            <Sidebar/>

            {showForm && (
                <div className="modal">
                    <div className="modal-content">
                        {/* Your form content goes here */}
                        <form onSubmit={e => handleSubmit(e)}>
                            <h2>Write a post</h2>

                            <div className='post_input'>
                                <label htmlFor='title'>
                                    Title
                                </label>
                                <input
                                    type='text'
                                    value={title}
                                    id='title'
                                    className={getTitleClass()} // Set the class dynamically
                                    placeholder='Your post title'
                                    onChange={e => setTitle(e.target.value)}
                                />
                                <p className={getTitleLength() > 255 ? "too-long" : ""}
                                   style={{marginTop: '2px', marginBottom: '0'}}>({getTitleLength()}/255)</p>
                            </div>

                            <div className='post_input'>
                                <Multiselect
                                    style={{maxWidth: '50%', color: 'black'}}
                                    isObject={true}
                                    onKeyPressFn={() => {
                                    }}
                                    onRemove={(selectedList, removeItem) => {
                                        setSelected(selected.filter((val) => val !== removeItem));
                                    }}
                                    onSearch={() => {}}
                                    onSelect={handleSelect}
                                    options={tags}
                                    displayValue='tag'
                                />
                            </div>

                            <div className='post_input'>
                                <label htmlFor='content'>
                                    Description
                                </label>
                                <textarea value={content}
                                          placeholder='Content of your post'
                                          id='content' rows={6}
                                          onChange={e => setContent(e.target.value)}/>
                            </div>

                            <div className='action_buttons'>
                                <button className='submit_post_button' type='submit'>
                                    <img src={tick} alt='cross'/>
                                    Create Post
                                </button>
                                <button onClick={e => setShowForm(false)}
                                        className='exit_post_create'>
                                    <img src={cross} width={24} height={24} alt='cross'/>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <div className="feed">
                <Search/>
                <div id="error_body" className="error">Hello</div>
                <button onClick={e => setShowForm(true)} className='create-post'>
                    <img src={pen} alt='create_post_icon'/>
                    <p>
                        What's on your mind?
                    </p>
                </button>

                {showPosts ? renderPosts() : <></>}

            </div>

            {active ? <ActiveThreads u={user}/> : <></>}
        </div>
    )
}