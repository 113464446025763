import React, {useEffect, useState} from 'react';
import './GroupPage.css';
import Sidebar from "../../Elements/Sidebar/Sidebar";
import Search from "../../Elements/SearchBar/Search";
import {Link, useParams} from "react-router-dom";
import makeRequest from "../../Elements/makeRequest";
import Post from "../../Elements/Post/Post";
import tick from "../../Images/post-buttons/tick.png";
import cross from "../../Images/white-cross.png";
import pen from "../../Images/Pen.png";
import {initializeApp} from "firebase/app";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";

export default function GroupPage() {
    const [groupPosts, setGroupPosts] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const [group, setGroup] = useState({});
    let params = useParams();

    // const [tags, setTags] = useState([]);
    useEffect(() => {
        async function fetchGroupPosts() {
            await makeRequest('api/groups/' + params.id.toString() + '/posts', 'GET')
                .then(data => {
                    setGroupPosts(data.posts)
                })
        }
        fetchGroupPosts()

        async function fetchGroupInfo() {
            await makeRequest('api/groups/' + params.id.toString(), 'GET')
                .then(data => {
                    setGroup(data.group)
                })
        }

        fetchGroupInfo()

        // fetch(process.env.REACT_APP_SERVER_URI + "/api/post/tags")
        //     .then(res => res.json())
        //     .then(data => {
        //         console.log(data);
        //         if (data.error) {
        //             displayError(data.error)
        //         } else {
        //             setTags(data.tags);
        //         }
        //     })
    }, []);

    const displayError = (message) => {
        let error = document.getElementById('error')
        error.innerText = message;
        error.style.display = 'block';
    }

    const displaySuccess = (message) => {
        let success = document.getElementById('success')
        success.innerText = message;
        success.style.display = 'block';
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        // const file = e.target[0]?.files[0]
        //
        // if (file) {
        //     const storageRef = ref(storage, `groups/${file.name}`);
        //     const uploadTask = uploadBytesResumable(storageRef, file);
        //
        //     uploadTask.on("state_changed",
        //         (snapshot) => {
        //             console.log('Uploading group main photo file...')
        //         },
        //         (error) => {
        //             displayError(error);
        //         },
        //         () => {
        //             getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        //                 setImgUrl(downloadURL)
        //             });
        //         }
        //     );
        // }

        uploadData()
    }

    async function uploadData() {
        await makeRequest('api/groups/' + params.id.toString() + '/posts/create', 'POST', {
            "post": {
                'title': title,
                'description': content,
                'photo_url': "no_photo",
            },
            'token': localStorage.getItem('token')
        })
            .then(data => {
                if (data.error) {
                    displayError(data.error)
                } else {
                    displaySuccess(data.message)
                }
            })
    }

    const getTitleLength = () => {
        return title.length;
    };

    const getTitleClass = () => {
        return getTitleLength() > 30 ? 'too-long' : '';
    };

    return (
        <div className='group_page'>
            <Sidebar/>

            {showForm && (
                <div className="modal">
                    <div className="modal-content">
                        <form onSubmit={e => handleSubmit(e)}>
                            <h2>Write a post</h2>

                            <div className='post_input'>
                                <label htmlFor='title'>
                                    Title
                                </label>
                                <input
                                    type='text'
                                    value={title}
                                    id='title'
                                    className={getTitleClass()}
                                    placeholder='Your post title'
                                    onChange={e => setTitle(e.target.value)}
                                />
                                <p className={getTitleLength() > 60 ? "too-long" : ""}
                                   style={{marginTop: '2px', marginBottom: '0'}}>({getTitleLength()}/60)</p>
                            </div>

                            <label style={{color: 'rgb(255,114,118)'}} htmlFor="text__input">
                                *Tags will be applied by admins upon verification
                            </label>

                            <div className='post_input'>
                                <label htmlFor='content'>
                                    Description
                                </label>
                                <textarea value={content}
                                          placeholder='Content of your post'
                                          id='content' rows={6}
                                          onChange={e => setContent(e.target.value)}/>
                            </div>

                            <div className='action_buttons'>
                                <button className='submit_post_button' type='submit'>
                                    <img src={tick} alt='cross'/>
                                    Create Post
                                </button>
                                <button onClick={e => setShowForm(false)}
                                        className='exit_post_create'>
                                    <img src={cross} width={24} height={24} alt='cross'/>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <div className='error' id='error'></div>
            <div className='success' id='success'></div>
            <div className='group_page_feed'>
                <div className='posts_feed'>
                    <Search groupId={params.id}/>

                    <button onClick={e => setShowForm(true)} className='create-post'>
                        <img src={pen} alt='create_post_icon'/>
                        <p>
                            Write a post for this group!
                        </p>
                    </button>

                    <div className='group_posts'>
                        {groupPosts && groupPosts.length > 0 ? (
                            groupPosts.map((obj, index) => (
                                <Link to={"/posts/" + obj.id} key={index}>
                                    <Post
                                        key={index}
                                        index={index}
                                        id={obj.id}
                                        title={obj.title}
                                        tags={obj.tags}
                                        upvotesAmount={obj.upvotes_amount}
                                        postAuthor={obj.author}
                                        description={obj.description}
                                        comments={obj.comments}
                                        rep={obj.rep}
                                    />
                                </Link>
                            ))
                        ) : (
                            <>
                                <h1 style={{color: 'white'}}>No posts found</h1>

                            </>
                        )}

                    </div>
                </div>
            </div>

            {group ?
                <div className='group_info'>
                    <img src={group.photo_url} alt={group.title} className='group_icon_big'/>
                    <div className="group_header">
                        <h2 className='group_info_title'>{group.title}</h2>
                        <p className='group_info_desc'>
                            {group.description}
                        </p>
                        <p className='group_info_followers'>
                            Supporters: {group.followers}
                        </p>
                        <p className='group_info_timestamp'>
                            Created at: {String(group.created_at).substring(0, 10)}
                        </p>
                    </div>
                </div>
                : <></>}
        </div>
    )
}