
import {
    CardElement,
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import "./DonateForm.css";
import makeRequest from "../makeRequest";
import storeMessage from "../DB/StoreMessage";

let CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "#000",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#fce883" },
            "::placeholder": { color: "#87bbfd" },
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee",
        },
    },
};

export default function PaymentForm() {
    const [email, setEmail] = useState('');
    const [value, setValue] = useState(0);
    const [success, setSuccess] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    let message = 'empty';

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const storeMessageToDB = () => {
        storeMessage({email, message});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe) {
            return;
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
            console.error(submitError);
            return;
        }

        // Create the PaymentIntent and obtain clientSecret
        let response = await makeRequest(
            "api/createCheckoutSession",
            "POST",
                {
                    "amount": Number(value) * 100,
                    "token": localStorage.getItem('token'),
                });

        const clientSecret = response.clientSecret;

        const { error } = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
                return_url: "https://www.omterp.com/#/thankYouForOrder",
            },
        });

        if (error) {
            console.error(error);
        } else {
            setSuccess(true);
        }
    };

    const handleEmailSubmit = (event) => {
        event.preventDefault();
        setShowPaymentForm(true);
    };

    return (
        <div className="payments">
            <form onSubmit={handleSubmit}>
                <fieldset className="FormGroup">
                    <div className="FormRow">
                        <PaymentElement options={CARD_OPTIONS}/>
                    </div>

                    <label>Please, enter Your Email:</label>
                    <input
                        className="submit__email"
                        placeholder="Your email :-)"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />

                    <input
                        className="submit__email"
                        placeholder="Tip amount"
                        style={{marginTop: "15px"}}
                        type="number"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </fieldset>

                <button className="payButton">Tip!</button>
            </form>
        </div>
    );
}
