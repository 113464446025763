import React from 'react';
import './StatisticsCards.css';
import {FaUserAlt} from "react-icons/fa";

export default function StatisticsCard({ data, title }) {
    return (
        <div className="statistics_card">
            <div className="stats_card_icon">
                <FaUserAlt style={{height: '64px', width: '64px'}} />
            </div>
            <div className="stats_card_content">
                <h1>{title}</h1>
                <h5>{data}</h5>
            </div>
        </div>
    )
}